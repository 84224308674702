import React, { useMemo } from 'react';
import classNames from 'classnames';
import { withStyles, Button } from '@material-ui/core';
import I18n from '@components/I18n';
import Spinner from '@components/Spinner';
import { blue2 } from '@config/colors';

const circularProgressProps = { style: { color: blue2 } };

const styles = {
  buttons: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 84,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 2%',
    borderTop: '2.5px solid rgba(29, 49, 16, 0.2)',
    background: 'white',
    '& button': {
      fontSize: 20,
      width: '45%'
    },
    '&.one-button': {
      justifyContent: 'center',
      '& button': {
        width: '70%'
      }
    }
  },
  cancelBtn: {
    color: blue2
  },
  confirmBtn: {
    color: 'white',
    background: blue2,

    '&:hover': {
      background: blue2
    }
  },
  spinner: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  }
};

function FormActionButtons({
  disableSubmit,
  onCancel,
  hideCancel,
  cancelLabel,
  submitLabel,
  isSubmitting,
  classes
}) {
  return (
    <div className={classNames(classes.buttons, hideCancel && 'one-button')}>
      {!hideCancel && (
        <Button className={classes.cancelBtn} type="button" onClick={onCancel}>
          <I18n>{cancelLabel || 'G.cancel'}</I18n>
        </Button>
      )}
      <Button
        className={classes.confirmBtn}
        disabled={disableSubmit || isSubmitting}
        variant="contained"
        type="submit">
        <I18n>{submitLabel || 'G.continue'}</I18n>
        {isSubmitting && <Spinner className={classes.spinner} props={circularProgressProps} />}
      </Button>
    </div>
  );
}

export default withStyles(styles)(FormActionButtons);
