import Alert from './Alert';

/**
 * Manages the app dialogs. Active dialogs are kept in an internal array.
 */
function DialogsManager() {
  let dialogs = [];
  let subscribers = [];
  let id = 0;

  /**
   * Open a dialog. Receives a Component to put inside the dialog, args
   * to pass into the dialog (as args prop), and props for the Material-UI Dialog Component.
   * Returns a promise that resolves when the dialog closes with an output value.
   */
  function open(Component, args, props = {}) {
    return new Promise(resolve => {
      dialogs = dialogs.concat({
        args,
        props,
        id: id++,
        Component,
        resolve
      });
      subscribers.forEach(({ cb }) => cb(dialogs));
    });
  }

  /**
   * Close a dialog. Requires a dialog-id (from dialogs array).
   * Can supply an output value for the promise resolve of open().
   */
  function close(id, value) {
    const dialog = dialogs.find(d => d.id === id);
    dialogs = dialogs.filter(d => d.id !== id);
    subscribers.forEach(({ cb }) => cb(dialogs));
    dialog.resolve(value);
  }

  /**
   * Subscribe to changes in dialogs array. Returns an unsubscribe function.
   */
  function subscribe(cb) {
    const ref = { cb };
    subscribers.push(ref);
    cb(dialogs);
    function unsubscribe() {
      subscribers = subscribers.filter(s => s !== ref);
    }
    return { unsubscribe };
  }

  /**
   * Opens a configurable alert dialog.
   */
  function openAlert(cfg) {
    const withDefaults = Object.assign(
      {},
      {
        text: '',
        addNo: false,
        success: true,
        withoutIcon: false,
        overrideButtonLabels: null
      },
      cfg
    );
    return open(Alert, withDefaults, { fullWidth: true });
  }

  return {
    open,
    close,
    subscribe,
    openAlert
  };
}

export default DialogsManager();
