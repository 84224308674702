import * as colors from './colors';

export default {
  palette: {
    type: 'light',
    primary: { main: colors.blue2 }
  },
  typography: {
    fontSize: 16
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 400
      },
      contained: {
        borderRadius: 0,
        boxShadow: 'none',

        '&:active': {
          boxShadow: 'none'
        }
      }
    }
  }
};
