import { createAction, handleActions } from 'redux-actions';

export const appMinVersionFailed = createAction('[app] APP_MIN_VERSION_FAILED');

const initialState = {
  appMinVersionFailed: false
};

export default handleActions(
  {
    [appMinVersionFailed]: (state, { payload }) => {
      return {
        ...state,
        appMinVersionFailed: true,
        platform: payload.platform,
        linkToStore: payload.linkToStore
      };
    }
  },
  initialState
);
