import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { tr } from '@utils/i18nService';

function I18n({ className, style, children, component, ...rest }) {
  const lang = useSelector(state => state.lang.selectedLang);
  const text = useMemo(() => {
    // If children is not a string, transparently render the children as usual.
    return typeof children === 'string' ? tr(children, rest, lang) : children;
  }, [children, lang, rest]);

  return React.createElement(component || 'span', { className, style }, text);
}

export default I18n;
