import React, { Fragment, useState, useEffect } from 'react';
import DialogWrapper from './DialogWrapper';
import dialogsManager from './DialogsManager';

/**
 * A container for the dialogs. Interacts with the DialogsManager.
 */
export default function DialogsContainer() {
  const [dialogs, setDialogs] = useState([]);
  useEffect(() => {
    const sub = dialogsManager.subscribe(d => setDialogs(d));
    return () => sub.unsubscribe();
  }, []);
  return (
    <Fragment>
      {dialogs.map(({ id, args, props, Component }) => (
        <DialogWrapper key={id} id={id} args={args} props={props} Component={Component} />
      ))}
    </Fragment>
  );
}
