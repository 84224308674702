import { template } from 'lodash';
import moment from 'moment';
import { setLang } from '@redux/lang';
import { dispatch, getState } from '@redux/store';
import i18n from './i18n';

export function tr(key, obj, lang) {
  if (!lang) {
    lang = getState().lang.selectedLang;
  }
  const tmpl = i18n[lang][key];
  if (!tmpl) return key;
  if (!obj) return tmpl;
  const retval = template(tmpl)(obj);
  return retval;
}

/**
 * Changes the app-language in redux and performs side-effects:
 * Changes the moment locale.
 * You should always use this function and not the redux action.
 */
export function changeLanguage(lang) {
  // Changing the moment locale is required for correct formatting of dates.
  moment.locale(lang);

  dispatch(setLang(lang));
}
