import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CreditCardIcon from '@components/CreditCardIcon';
import I18n from '@components/I18n';
import { text1, blue2 } from '@config/colors';
import AuthChBookingGroup from './AuthChBookingGroup';
import AuthChPrice from './AuthChPrice';

const styles = {
  root: {
    paddingTop: 40,
    position: 'relative',
    paddingBottom: 100,
    fontSize: 22,
    color: text1(1)
  },
  icon: {
    marginBottom: 28,
    textAlign: 'center'
  },
  title: {
    textAlign: 'center'
  },
  column: {
    width: '50%'
  },
  bold: {
    fontWeight: 800,
    color: blue2
  },
  subtitle: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5%',
    paddingRight: '5%',
    background: 'linear-gradient(to right, rgba(0,171,223, 0.08), rgba(0,114,188, 0.08))',
    height: 47.5
  }
};

function AuthChConfirm({ classes, data }) {
  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <CreditCardIcon />
      </div>
      <I18n component="div" className={classes.title}>
        authenticateCharge.confirmMsg
      </I18n>
      <div className={classes.subtitle}>
        <div className={classes.column}>Total:</div>
        <AuthChPrice
          className={classNames(classes.column, classes.bold)}
          amount={data.amount}
          currency={data.currency}
        />
      </div>
      {data.paymentDetails.map((booking, idx) => (
        <AuthChBookingGroup key={idx} booking={booking} />
      ))}
    </div>
  );
}

export default withStyles(styles)(AuthChConfirm);
