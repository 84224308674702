import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Spinner from '@components/Spinner';

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
function AuthChLoadingData({ classes }) {
  return (
    <div className={classes.root}>
      <Spinner />
    </div>
  );
}

export default withStyles(styles)(AuthChLoadingData);
