// In some android devices, when opening the keyboard, we will receive
// 2 resize events. The 1st resize event is the wrong one and shows a height differential
// of less than 60 pixels from a closed keyboard. We ignore this event and only
// consider the keyboard open on the 2nd event.
const KEYBOARD_SIZE_THRESHOLD = 60;

/**
 * Allows consumers to listen to the keyboard state by subscribing.
 */
function KeyboardStateService() {
  let subscribers = [];
  let whenOpenSubscribers = [];
  let originalHeight;
  let isOpen = false;

  return {
    /**
     * Initialize the service.
     */
    init() {
      originalHeight = window.innerHeight;
      window.addEventListener('resize', onResize);
    },

    /**
     * Cleanup the service.
     */
    destroy() {
      window.removeEventListener('resize', onResize);
    },

    /**
     * Register a callback function to run when the keyboard status (isOpen) changes.
     */
    subscribe(cb) {
      const ref = { cb };
      subscribers.push(ref);
      function unsubscribe() {
        subscribers = subscribers.filter(s => s !== ref);
      }
      return { unsubscribe };
    },

    /**
     * Register a callback function to run once when the keyboard is opened.
     */
    whenOpen(cb) {
      const ref = { cb };
      whenOpenSubscribers.push(ref);
      function unsubscribe() {
        whenOpenSubscribers = whenOpenSubscribers.filter(s => s !== ref);
      }
      return { unsubscribe };
    }
  };

  function onResize(event) {
    const wasOpen = isOpen;
    isOpen = event.target.innerHeight < originalHeight - KEYBOARD_SIZE_THRESHOLD;

    if (wasOpen !== isOpen) {
      subscribers.forEach(({ cb }) => cb(isOpen));
    }
    if (!wasOpen && isOpen) {
      whenOpenSubscribers.forEach(({ cb }) => cb());
      whenOpenSubscribers = [];
    }
  }
}

export const keyboardStateService = KeyboardStateService();
