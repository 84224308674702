import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import iconsMap from '@utils/iconsMap';

const styles = {
  default: {
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

function Icon({ type, width, height, className, onClick, classes, ...rest }) {
  const iconSvg = useMemo(() => {
    if (!iconsMap[type]) {
      return;
    }
    const __html =
      width && height
        ? iconsMap[type].slice(0, 4) + ` width=${width} height=${height} ` + iconsMap[type].slice(4)
        : iconsMap[type];
    return { __html };
  }, [type, width, height]);

  return (
    <div
      draggable="false"
      onClick={onClick}
      {...rest}
      dangerouslySetInnerHTML={iconSvg}
      className={classNames('icon', classes.default, className)}
    />
  );
}

export default withStyles(styles)(Icon);
