import { setLoggedIn, setUser } from '@redux/login';
import { setAccount } from '@redux/account';
import { setParkingLot } from '@redux/parkingLot';
import { dispatch } from '@redux/store';
import { changeLanguage } from '@utils/i18nService';
import { getMyBookings } from './bookingsUtils';
import { api } from './data';
import { saveInStorage, getFromStorage, removeFromStorage } from './storage';
import dialogsManager from '@dialogs/DialogsManager';

export function initLogin(data) {
  saveCredentials(data);
  return getUser();
}

export async function getUser() {
  const user = await api({ type: 'getCurrent', disableErrorHandle: true });
  dispatch(setUser(user));
  changeLanguage(user.language);
  const account = await fetchAccount(user.accountId);
  await fetchParkingLot(account._id);
  // We must populate all data before setLoggedIn.
  dispatch(setLoggedIn());
  getMyBookings();
  return user;
}

export function login(credentials) {
  return api({
    type: 'login',
    data: credentials,
    dontSendHeaders: true
  }).then(async res => {
    if (res.errKey === 'error.confirmRegistration') {
      const res = await dialogsManager.openAlert({
        text: 'error.confirmRegistration',
        success: false,
        addNo: true,
        overrideButtonLabels: {
          yes: 'login.resendConfirmRegistration',
          no: 'login.dontResendConfirmRegistration'
        }
      });
      if (res)
        return api({
          type: 'resendConfirmRegistration',
          data: { email: credentials.email }
        });
    } else {
      return initLogin(res);
    }
  });
}

export function resetPassword(credentials) {
  return api({
    type: 'resetPassword',
    dontSendHeaders: true,
    data: credentials
  });
}

export function setInitialPasswordAndLogin(credentials) {
  return resetPassword(credentials).then(res => initLogin(res));
}

export function saveCredentials(credentials) {
  saveInStorage('AUTH_CREDENTIALS', credentials);
}

export function clearCredentials() {
  removeFromStorage('AUTH_CREDENTIALS');
}

export function getStoredCredentials() {
  return getFromStorage('AUTH_CREDENTIALS');
}

function fetchAccount(accountId) {
  return api({
    type: 'getAccount',
    data: { accountId }
  }).then(res => {
    dispatch(setAccount(res));
    return res;
  });
}

function fetchParkingLot(accountId) {
  return api({
    type: 'getParkingLots',
    data: { accountId }
  }).then(res => {
    dispatch(setParkingLot(res[0]));
    return res[0];
  });
}
