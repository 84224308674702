require('core-js');

import React from 'react';
import { render } from 'react-dom';
import store from './redux/store';
import './style/index.scss';
import Root from './containers/Root';
import { setHeader } from './utils/data';
import { cerulean } from './config/colors';
import { isIos } from './utils/mobileDetect';

if ('cordova' in window) {
  document.addEventListener('deviceready', () => {
    // We configure the statusbar.
    StatusBar.overlaysWebView(false);
    StatusBar.backgroundColorByHexString(cerulean);

    // We prevent moving to layout orientation.
    screen.orientation.lock('portrait');

    cordova.getAppVersion.getVersionNumber().then(version => {
      setHeader('alpha-app-version', `${device.platform.toLowerCase()} ${version}`);
      setBodyHeightAsWindowHeight();
      renderApp();
    });
  });
} else {
  document.addEventListener('DOMContentLoaded', () => {
    setBodyHeightAsWindowHeight();
    renderApp();
  });
}

/**
 * This is done to prevent absolutely positioned elements on
 * the bottom to go up when opening keyboard on Android Browsers(!).
 */
function setBodyHeightAsWindowHeight() {
  const height = isIos ? screen.height : window.innerHeight;
  document.body.style.height = `${height}px`;
}

function renderApp() {
  render(<Root store={store} />, document.getElementById('root'));
}
