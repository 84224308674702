import React, { useMemo } from 'react';
import I18n from '@components/I18n';
import { formatPrice } from '@utils/utils';

function AuthChPrice({ amount, currency, className }) {
  const price = useMemo(() => formatPrice(amount), [amount]);

  return (
    <div className={className}>
      <I18n>{`currency.${currency}.abbreviated`}</I18n>
      <span>&nbsp;</span>
      <span>{price}</span>
    </div>
  );
}

export default React.memo(AuthChPrice);
