import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import TextField from '@components/TextField';
import FieldError from '@components/FieldError';
import Button from '@components/Button';
import I18n from '@components/I18n';
import dialogsManager from '@dialogs/DialogsManager';
import { validations } from '@utils/validations';
import { api } from '@utils/data';

const styles = {
  wrapper: {
    background: 'linear-gradient(#00a2e233, #00a2e200)',
    padding: '66px 10.5px 32px'
  },
  title: {
    fontSize: 22,
    textAlign: 'center',
    marginBottom: 10
  },
  emailInput: {
    fontSize: '20px !important'
  },
  btnWrapper: {
    marginTop: '43px'
  },
  submitBtn: {
    width: '100%',
    fontSize: '20px'
  }
};

const ForgotPasswordDialog = props => {
  const { classes, dismiss } = props;
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    // For validation of initial value.
    onEmailChange({ target: { value: email } });
  }, []);

  const onEmailChange = event => {
    const value = event.target.value;
    setEmail(value);
    if (validations['required'](value)) {
      setError({ errKey: 'error.required' });
    } else if (validations['email'](value)) {
      setError({ errKey: 'error.invalidEmail' });
    } else {
      setError(null);
    }
  };

  const resetPassword = event => {
    event.preventDefault();
    api({
      type: 'sendResetPasswordEmail',
      data: { email },
      disableErrorHandle: true
    })
      .then(res => {
        dismiss('success');
        dialogsManager.openAlert({ text: 'forgotPassword.successDialog', success: true });
      })
      .catch(err => {
        setTouched(true);
        setError({ errKey: err.data.errKey });
      });
  };

  return (
    <div className={classes.wrapper}>
      <I18n component="div" className={classes.title}>
        forgotPassword.title
      </I18n>
      <form onSubmit={resetPassword}>
        <div>
          <TextField
            errors={touched && error !== null}
            label={<I18n>G.email</I18n>}
            value={email}
            type="email"
            onChange={onEmailChange}
            onBlur={() => setTouched(true)}
          />
          {error && touched && <FieldError errors={error} />}
        </div>
        <div className={classes.btnWrapper}>
          <Button
            classes={{ button: classes.submitBtn }}
            type="submit"
            color="primary"
            label={<I18n>forgotPassword.submitButton</I18n>}
            disabled={error !== null}
          />
        </div>
      </form>
    </div>
  );
};

export default withStyles(styles)(ForgotPasswordDialog);
