import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@components/Button';
import Popover from '@material-ui/core/Popover';
import { getState } from '@redux/store';
import { api } from '@utils/data';
import { changeLanguage } from '@utils/i18nService';
import i18n from '@utils/i18n';

import List from '@material-ui/core/List';
import ListItemButton from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

function changeLang(newLangCode) {
  changeLanguage(newLangCode);
  const loggedIn = getState().login.loggedIn;

  if (loggedIn) {
    api({
      type: 'changeUserLanguage',
      disableBI: true,
      data: { language: newLangCode }
    });
  }
}

const buttonS = { borderRadius: 30 };

function LangButton({ className }) {
  const languageCodes = Object.keys(i18n)
    .filter(code => code !== 'key')
    .sort() // sort alphabetically
    .sort(code => {
      // but place DE and EN on top
      if (code === 'de') return -100;
      else if (code === 'en') return -99;
      else return 0;
    });

  const lang = useSelector(state => state.lang.selectedLang);
  let initialIndex = languageCodes.indexOf(lang);
  if (initialIndex === -1) initialIndex = 0;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(initialIndex);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    changeLang(languageCodes[index]);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        label={lang.toUpperCase()}
        style={buttonS}
        className={className}
        onClick={handleClick}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          style={{ cursor: 'pointer' }}>
          {languageCodes.map((langCode, index) => {
            return (
              <ListItemButton
                key={'LIB_' + langCode}
                selected={selectedIndex === index}
                onClick={event => handleListItemClick(event, index)}>
                <ListItemAvatar>
                  <Avatar>
                    <img
                      src={`https://flagcdn.com/48x36/${i18n[langCode]['lang.country']}.png`}
                      alt="Flag"
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={i18n[langCode]['lang.name']} />
              </ListItemButton>
            );
          })}
        </List>
      </Popover>
    </div>
  );
}

export default React.memo(LangButton);
