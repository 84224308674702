import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from '@redux/rootReducer';

let composeEnhancers;

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
  composeEnhancers = compose;
} else {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
export const dispatch = store.dispatch;
export const getState = store.getState;
