import { createAction, handleActions } from 'redux-actions';

export const startFetching = createAction('[transactionReport] START_FETCHING');
export const doneFetching = createAction('[transactionReport] DONE_FETCHING');
export const setData = createAction('[transactionReport] SET_DATA');
export const addPage = createAction('[transactionReport] ADD_PAGE');
export const setSelectedDate = createAction('[transactionReport] SET_SELECTED_DATE');
export const setTransactionId = createAction('[transactionReport] SET_TRANSACTION_ID');
export const startPayingOwners = createAction('[transactionReport] START_PAYING_OWNERS');
export const donePayingOwners = createAction('[transactionReport] DONE_PAYING_OWNERS');
export const setSelected = createAction('[transactionReport] SET_SELECTED');
export const setDateOf1stTransaction = createAction(
  '[transactionReport] SET_DATE_OF_1ST_TRANSACTION'
);

const initialState = {
  dateOf1stTransaction: null,
  data: null,
  currentPage: null,
  isLastPage: null,
  total: null,
  isLoadingData: false,
  isPayingOwners: false,
  selectedDate: null,
  transactionId: '',
  /**
   * { [ownerEmail]: <row-group data or null> }: a map indicating the checkbox
   * selection state per group in the table.
   */
  selected: {}
};

export default handleActions(
  {
    [startFetching]: state => {
      return { ...state, isLoadingData: true };
    },

    [doneFetching]: state => {
      return { ...state, isLoadingData: false };
    },

    /**
     * Sets the 1st page.
     */
    [setData]: (state, { payload: { currentPage, isLastPage, total, data } }) => {
      return {
        ...state,
        total,
        isLastPage,
        currentPage,
        data
      };
    },

    /**
     * Adds a page to the data.
     */
    [addPage]: (state, { payload: { currentPage, isLastPage, total, data } }) => {
      if (!data) {
        throw new Error('Cannot addPage when no 1st page exists!');
      }
      return {
        ...state,
        total,
        isLastPage,
        currentPage,
        data: [...state.data, ...data]
      };
    },

    [setSelectedDate]: (state, { payload }) => {
      return {
        ...state,
        selectedDate: payload
      };
    },

    [setTransactionId]: (state, { payload }) => {
      return {
        ...state,
        transactionId: payload
      };
    },

    [setDateOf1stTransaction]: (state, { payload }) => {
      return {
        ...state,
        dateOf1stTransaction: payload
      };
    },

    [startPayingOwners]: state => ({ ...state, isPayingOwners: true }),

    [donePayingOwners]: state => ({ ...state, isPayingOwners: false }),

    [setSelected]: (state, { payload }) => ({ ...state, selected: payload })
  },
  initialState
);
