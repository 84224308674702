import React, { useMemo } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import I18n from '@components/I18n';
import AuthChPrice from './AuthChPrice';

const styles = {
  root: {
    paddingLeft: '5%',
    paddingRight: '5%',
    borderBottom: '2.5px solid rgba(29, 49, 16, 0.2)',
    fontSize: 20,
    lineHeight: '28px',
    padding: '10px 0'
  },
  bold: {
    fontWeight: 800
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  column: {
    width: '50%'
  }
};

function AuthChBookingGroup({ classes, booking }) {
  const bookingDate = useMemo(() => moment(booking.bookingDate).format('DD/MM/YYYY'), [booking]);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <I18n className={classes.column}>authenticateCharge.bookingDate</I18n>
        <div className={classNames(classes.column, classes.bold)}>{bookingDate}</div>
      </div>
      <div className={classes.row}>
        <I18n className={classes.column}>authenticateCharge.parkingSpace</I18n>
        <div className={classNames(classes.column, classes.bold)}>
          {booking.parkingSlot.floorOrdinal} - {booking.parkingSlot.name}
        </div>
      </div>
      <div className={classes.row}>
        <I18n className={classes.column}>authenticateCharge.price</I18n>
        <AuthChPrice
          className={classNames(classes.column, classes.bold)}
          amount={booking.amount}
          currency={booking.currency}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(AuthChBookingGroup);
