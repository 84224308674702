export const apiMap = {
  login: {
    url: '/oauth/login',
    method: 'post'
  },
  getCurrent: {
    url: '/api/getCurrent',
    method: 'get'
  },
  getUsersForAdmin: {
    url: '/api/users/allUsersForAdmin',
    method: 'post'
  },
  adminAddUser: {
    url: '/api/users/adminAddUser',
    method: 'post'
  },
  deleteUser: {
    url: '/api/deleteUser',
    method: 'post'
  },
  changeUserLanguage: {
    url: '/api/users/changeLanguage',
    method: 'post'
  },
  deactivateUser: {
    url: '/api/deactivateUser',
    method: 'post'
  },
  reactivateUser: {
    url: '/api/reactivateUser',
    method: 'post'
  },
  switchUserRoles: {
    url: '/api/switchUserRoles',
    method: 'post'
  },
  getAccessControlApprovals: {
    url:
      '/api/accessControl/accessControlApprovals?pageNum=<%= urlParams.pageNum %>&pageLimit=<%= urlParams.pageLimit %>',
    method: 'get'
  },
  addAccessControlApproval: {
    url: '/api/accessControl/addAccessControlApproval',
    method: 'post'
  },
  editAccessControlApproval: {
    url: '/api/accessControl/editAccessControlApproval',
    method: 'post'
  },
  deleteAccessControlApproval: {
    url: '/api/accessControl/deleteAccessControlApproval',
    method: 'post'
  },
  getAccessReport: {
    url:
      '/api/accessControl/accessReport?pageNum=<%= urlParams.pageNum %>&pageLimit=<%= urlParams.pageLimit %>&startDate=<%= urlParams.startDate %>&endDate=<%= urlParams.endDate %>&licensePlate=<%= urlParams.licensePlate %>',
    method: 'get'
  },
  getAccessReportCSV: {
    url:
      '/api/accessControl/accessReportCSV?startDate=<%= urlParams.startDate %>&endDate=<%= urlParams.endDate %>&licensePlate=<%= urlParams.licensePlate %>&accessToken=<%= urlParams.accessToken %>',
    method: 'get'
  },
  getDateOfFirstEvent: {
    url: '/api/accessControl/dateOfFirstEvent',
    method: 'get'
  },
  editMyProfile: {
    url: '/api/editMyProfile',
    method: 'post'
  },
  editAllowPrebookingForUser: {
    url: '/api/editAllowPrebookingForUser',
    method: 'post'
  },
  editAffiliation: {
    url: '/api/editAffiliation',
    method: 'post'
  },
  changePassword: {
    url: '/api/changePassword',
    method: 'post'
  },
  refreshToken: {
    url: '/oauth/refreshToken',
    method: 'post'
  },
  signup: {
    url: '/oauth/signup',
    method: 'post'
  },
  confirmRegistration: {
    url: '/oauth/confirmRegistration',
    method: 'post'
  },
  resendConfirmRegistration: {
    url: '/oauth/resendConfirmRegistration',
    method: 'post'
  },
  decodeAuthRegToken: {
    url: '/oauth/decodeRegToken',
    method: 'post'
  },
  isValidPasswordResetToken: {
    url: '/oauth/isValidPasswordResetToken',
    method: 'post'
  },
  getAllowedDomains: {
    url: '/oauth/allowedDomains',
    method: 'get'
  },
  getAccount: {
    url: '/api/account/getAccount',
    method: 'post'
  },
  getParkingLots: {
    url: '/api/parkingLot/getParkingLots',
    method: 'post'
  },
  getMyParkingSlots: {
    url: '/api/parkingSlot/mine?parkingLotId=<%= urlParams.parkingLotId %>',
    method: 'get'
  },
  assignable: {
    url: '/api/parkingSlot/assignable?parkingLotId=<%= urlParams.parkingLotId %>',
    method: 'get'
  },
  getAvailableParkingSlots: {
    url: '/api/parkingSlot/unassigned?parkingLotId=<%= urlParams.parkingLotId %>',
    method: 'get'
  },
  assignParkingSlot: {
    url:
      '/api/parkingSlot/<%= urlParams.parkingSlotId %>/assign?assignee=<%= urlParams.assignee %>',
    method: 'post'
  },
  unassignParkingSlot: {
    url: '/api/parkingSlot/<%= urlParams.parkingSlotId %>/unassign',
    method: 'post'
  },
  getMyBookings: {
    url: '/api/parkingSlot/getMyBookings?parkingLotId=<%= urlParams.parkingLotId %>',
    method: 'get'
  },
  getOwnerBookings: {
    url:
      '/api/parkingSlot/ownerBookings?parkingLotId=<%= urlParams.parkingLotId %>&pageNum=<%= urlParams.pageNum %>&pageLimit=<%= urlParams.pageLimit %>',
    method: 'get'
  },
  getParkingSlotAvailability: {
    url: '/api/parkingSlotAvailability/<%= urlParams.parkingSlotId %>/<%= urlParams.month %>',
    method: 'get'
  },
  getParkingSlotsAvailabilityByDate: {
    url: '/api/parkingSlot/parkingSlotAvailabilityByDate',
    method: 'post'
  },
  makeParkingSlotsAvailable: {
    url: '/api/parkingSlotAvailability/makeAvailable',
    method: 'post'
  },
  makeParkingSlotsUnavailable: {
    url: '/api/parkingSlotAvailability/makeUnavailable',
    method: 'post'
  },
  isParkingSlotOccupied: {
    url: '/api/parkingSlot/<%= urlParams.parkingSlotId %>/isOccupied',
    method: 'get'
  },
  customerAcceptTermsAndConditions: {
    url: '/api/users/customerAcceptTermsAndConditions',
    method: 'post'
  },
  bookParkingSlot: {
    url: '/api/parkingSlot/book',
    method: 'post'
  },
  getAllBookings: {
    url: '/api/parkingSlot/getAllBookings?parkingLotId=<%= urlParams.parkingLotId %>',
    method: 'get'
  },
  getBookingsCSV: {
    url:
      '/api/parkingSlot/getBookingsCSV?parkingLotId=<%= urlParams.parkingLotId %>&lang=<%= urlParams.lang %>&filterOption=<%= urlParams.filterOption %>&timezone=<%= urlParams.timezone %>&accessToken=<%= urlParams.accessToken %>',
    method: 'get'
  },
  prepareAddCreditCard: {
    url: '/api/payment/prepareAddCreditCard',
    method: 'post'
  },
  getAuthenticateChargeDetails: {
    url: '/api/payment/authenticateChargeDetails?token=<%=urlParams.token%>',
    method: 'get'
  },
  prepareConfirmPayment: {
    url: '/api/payment/prepareConfirmPayment',
    method: 'post'
  },
  addCreditCard: {
    url: '/api/payment/addCreditCard',
    method: 'post'
  },
  cancelBooking: {
    url: '/api/parkingSlot/cancelBooking',
    method: 'post'
  },
  getPaymentsStatus: {
    url: '/api/payment/getPaymentsStatus',
    method: 'get'
  },
  stripeReauth: {
    url: '/api/payment/stripeReauth',
    method: 'get'
  },
  addOwnerStripeInfo: {
    url: '/api/payment/addOwnerStripeInfo',
    method: 'post'
  },
  editOwnerStripeInfo: {
    url: '/api/payment/editOwnerStripeInfo',
    method: 'post'
  },
  getTransactionsReport: {
    url:
      '/api/payment/transactionReport?pageNum=<%= urlParams.pageNum %>&pageLimit=<%= urlParams.pageLimit %>&date=<%= urlParams.date %>&chargeId=<%= urlParams.chargeId %>',
    method: 'get'
  },
  getOutstandingPaymentsReport: {
    url:
      '/api/payment/outstandingPaymentsReport?fromDate=<%= urlParams.fromDate %>&toDate=<%= urlParams.toDate %>&accessToken=<%= urlParams.accessToken %>',
    method: 'get'
  },
  getDateOf1stTransaction: {
    url: '/api/payment/dateOfFirstTransaction',
    method: 'get'
  },
  transferFundsToOwners: {
    url: '/api/payment/transferFundsToOwners',
    method: 'post'
  },
  sendResetPasswordEmail: {
    url: '/oauth/sendResetPasswordEmail',
    method: 'post'
    // mock: 'success',
    // errMock: {
    //   response: {
    //     status: 400,
    //     data: {
    //       errKey: 'error.emailDoesntExist'
    //     }
    //   }
    // }
  },
  getCurrentBankAccount: {
    url: '/api/bankAccount/current',
    method: 'get'
  },
  resetPassword: {
    url: '/oauth/resetPassword',
    method: 'post'
  }
};
