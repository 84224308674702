import { createAction, handleActions } from 'redux-actions';

export const setAllBookings = createAction('SET_ALL_BOOKINGS');
export const setFilteredBookings = createAction('SET_FILTERED_BOOKINGS');
export const updateAfterCancel = createAction('UPDATE_AFTER_CANCEL');

const initialState = {
  allBookings: [],
  filteredBookings: []
};

export default handleActions(
  {
    [setAllBookings]: (state, action) => {
      return {
        ...state,
        allBookings: action.payload
      };
    },
    [setFilteredBookings]: (state, action) => {
      return {
        ...state,
        filteredBookings: action.payload
      };
    },
    [updateAfterCancel]: (state, action) => {
      return {
        ...state,
        allBookings: state.allBookings.filter(booking => !(booking.id == action.payload)),
        filteredBookings: state.filteredBookings.filter(booking => !(booking.id == action.payload))
      };
    }
  },
  initialState
);
