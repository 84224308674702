import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import dialogsManager from '@dialogs/DialogsManager';
import ForgotPasswordDialog from '@dialogs/forgotPassword/ForgotPasswordDialog';
import TextField from '@components/TextField';
import FieldError from '@components/FieldError';
import Button from '@components/Button';
import LangButton from '@components/LangButton';
import PasswordField from '@components/PasswordField';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import BottomSection from '@components/BottomSection';
import * as colors from '@config/colors';
import * as LoginActions from '@redux/login';
import { validations } from '@utils/validations';
import wrapActionCreators from '@utils/wrapActionCreators';
import { initLogin, login } from '@utils/auth';
import { changeLanguage } from '@utils/i18nService';
import { api } from '@utils/data';

const styles = {
  wrapper: {
    height: '100%',
    margin: 'auto',
    position: 'relative'
  },
  scrollableWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1
  },
  topSection: {
    textAlign: 'right',
    position: 'relative',
    padding: 10
  },
  mainSection: {
    marginTop: 30,
    width: '300px',
    margin: 'auto',
    position: 'relative'
  },
  forgotPassword: {
    color: colors.blue2,
    cursor: 'pointer',
    textAlign: 'right',
    fontSize: 14,
    marginTop: 16
  },
  notAUser: {
    color: '#404041',
    marginTop: 40,
    textAlign: 'center'
  }
};

const mapStateToProps = state => ({
  login: state.login
});

class LoginPage extends Component {
  state = {
    errors: {},
    touched: {
      email: false,
      password: false
    }
  };

  componentDidMount() {
    // This is needed for initial detection of errors.
    ['email', 'password'].forEach(field => this.updateField(field, this.state[field]));
    this.confirmRegistration();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.token !== prevProps.match.params.token) {
      this.confirmRegistration();
    }
  }

  confirmRegistration() {
    const { match } = this.props;
    if (match.params.lang && match.params.lang !== 'null') {
      changeLanguage(match.params.lang);
    }
    if (match.params.token) {
      api({
        type: 'confirmRegistration',
        data: { token: match.params.token }
      }).then(res => {
        return initLogin(res);
      });
    }
  }

  login = event => {
    event.preventDefault();
    login({
      email: this.state.email,
      password: this.state.password
    });
  };

  forgotPassword = () => {
    dialogsManager.open(ForgotPasswordDialog);
  };

  validate = (name, value) => {
    let errors = [];
    switch (name) {
      case 'email':
        if (validations['required'](value)) errors.push({ errKey: 'error.required' });
        if (validations['email'](value)) errors.push({ errKey: 'error.invalidEmail' });
        break;
      case 'password':
        if (validations['required'](value)) errors.push({ errKey: 'error.required' });
        break;
    }
    return errors;
  };

  sumErrors = () => {
    let total = 0;
    Object.entries(this.state.errors).forEach(fieldError => {
      total += fieldError[1].length;
    });
    return total;
  };

  updateField(fieldName, fieldValue) {
    this.state[fieldName] = fieldValue;
    this.state.errors[fieldName] = this.validate(fieldName, fieldValue);

    this.setState({
      ...this.state,
      totalErrors: this.sumErrors()
    });
  }

  setTouched(field) {
    if (!this.state.touched[field]) {
      this.setState({
        touched: {
          ...this.state.touched,
          [field]: true
        }
      });
    }
  }

  hasError(field) {
    return (this.state.errors[field] || '').length > 0;
  }

  render() {
    const {
      classes,
      login: {
        loggedIn,
        permissions: { defaultRoute },
        user
      }
    } = this.props;
    const showUsernameErr = this.state.touched.email && this.hasError('email');
    const showPasswordErr = this.state.touched.password && this.hasError('password');
    const isLoginBtnDisabled = this.state.totalErrors > 0;

    if (loggedIn) {
      const redirectTo =
        user.role && user.role.includes('PARKING_CUSTOMER') && !user.licensePlate
          ? '/sys/profile'
          : defaultRoute;
      return <Redirect to={redirectTo} />;
    }

    return (
      <div className={classes.wrapper}>
        <div className={classNames(classes.scrollableWrapper, 'scrollable-main')}>
          <div>
            <div className={classes.topSection}>
              <LangButton />
            </div>
            <Icon type="logo" width={408} height={90} />
          </div>
          <form className={classes.mainSection} onSubmit={this.login}>
            <div style={{ marginTop: 10 }}>
              <TextField
                errors={showUsernameErr}
                label={<I18n>G.email</I18n>}
                value={this.state.email}
                type="email"
                onChange={event => this.updateField('email', event.target.value)}
                onBlur={() => this.setTouched('email')}
              />
              {showUsernameErr && <FieldError errors={this.state.errors['email'][0]} />}
            </div>
            <div style={{ marginTop: 10 }}>
              <PasswordField
                errors={showPasswordErr}
                id="password"
                label={<I18n>G.password</I18n>}
                value={this.state.password}
                onChange={event => this.updateField('password', event.target.value)}
                onBlur={() => this.setTouched('password')}
              />
              {showPasswordErr && <FieldError errors={this.state.errors['password'][0]} />}
              <div className={classes.forgotPassword} onClick={this.forgotPassword}>
                <I18n>login.forgotPassword</I18n>
              </div>
              <div style={{ marginTop: 40 }}>
                <Button
                  type="submit"
                  label={<I18n>login.login</I18n>}
                  style={{ width: '100%', height: '51.1' }}
                  disabled={isLoginBtnDisabled}
                />
              </div>
            </div>
            <div className={classes.notAUser}>
              <I18n>login.notUser</I18n>&nbsp;
              <Link to="/signup" style={{ color: colors.blue2 }}>
                {<I18n>G.signup</I18n>}
              </Link>
            </div>
          </form>
        </div>
        <BottomSection />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  wrapActionCreators(LoginActions)
)(withStyles(styles)(LoginPage));
