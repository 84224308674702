import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppMinVersionFailedDialog from '@components/AppMinVersionFailedDialog';
import { hideSplashScreen } from '@utils/utils';
import { usePrevious } from '@utils/hooks';

const wrapperS = { height: '100%' };

function AppMinVersionVerifier({ children }) {
  const failed = useSelector(state => state.app.appMinVersionFailed);
  const platform = useSelector(state => state.app.platform);
  const linkToStore = useSelector(state => state.app.linkToStore);
  const prevFailed = usePrevious(failed);
  if (!prevFailed && failed) {
    hideSplashScreen();
  }
  return (
    <div style={wrapperS}>
      {children}
      {failed && <AppMinVersionFailedDialog platform={platform} linkToStore={linkToStore} />}
    </div>
  );
}

export default withRouter(AppMinVersionVerifier);
