import React from 'react';
import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import I18n from './I18n';
import LangButton from './LangButton';
import Button from './Button';
import Icon from './Icon';
import { text1 } from '../config/colors';

const styles = {
  topSection: {
    textAlign: 'right',
    position: 'relative',
    padding: 10
  },
  mainSection: {
    padding: '10px 26px',
    color: text1(1)
  },
  logo: {
    marginTop: 14
  },
  header: {
    textAlign: 'center',
    marginTop: 42,
    marginBottom: 28
  },
  buttonWrapper: {
    marginTop: 34,
    textAlign: 'center'
  }
};

function AppMinVersionFailedDialog({ classes, platform, linkToStore }) {
  return (
    <Dialog open fullScreen transitionDuration={0}>
      <div className={classes.topSection}>
        <LangButton />
      </div>
      <div className={classes.mainSection}>
        <Icon className={classes.logo} type="logo" width={204} height={81.5} />
        <h1 className={classes.header}>
          <I18n>appMinVersion.title</I18n>
        </h1>
        <p>
          <I18n platform={platform}>appMinVersion.message</I18n>
        </p>
        <div className={classes.buttonWrapper}>
          <Button
            component="a"
            color="primary"
            label={<I18n>appMinVersion.updateBtn</I18n>}
            target="_blank"
            href={linkToStore}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(AppMinVersionFailedDialog);
