import { createAction, handleActions } from 'redux-actions';
import { permissions } from '@config/permissions';
import { removeFromStorage } from '@utils/storage';

export const setLoggedIn = createAction('SET_LOGGED_IN');
export const setLoggedOut = createAction('SET_LOGGED_OUT');
export const setIdle = createAction('SET_IDLE');
export const setActive = createAction('SET_ACTIVE');
export const setUser = createAction('SET_USER');

const initialState = {
  loggedIn: false,
  user: {},
  permissions: {},
  isIdle: false
};

export default handleActions(
  {
    [setLoggedIn]: state => ({ ...state, loggedIn: true }),

    [setLoggedOut]: () => {
      removeFromStorage('AUTH_CREDENTIALS');
      return initialState;
    },

    [setUser]: (state, action) => ({
      ...state,
      user: action.payload,
      permissions: permissions[action.payload.role]
    }),

    [setIdle]: state => ({ ...state, isIdle: true }),

    [setActive]: state => ({ ...state, isIdle: false })
  },
  initialState
);
