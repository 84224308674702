import { createAction, handleActions } from 'redux-actions';

export const setSelectedFloorIdx = createAction('SET_SELECTED_FLOOR_IDX');

const initialState = {
  selectedFloorIdx: 0
};

export default handleActions(
  {
    [setSelectedFloorIdx]: (state, action) => {
      return { ...state, selectedFloorIdx: action.payload };
    }
  },
  initialState
);
