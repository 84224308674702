import { createAction, handleActions } from 'redux-actions';

export const setLang = createAction('SET_LANG');

const initialState = {
  selectedLang: 'de'
};

export default handleActions(
  {
    [setLang]: (state, action) => {
      return { ...state, selectedLang: action.payload };
    }
  },
  initialState
);
