import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core';
import Icon from './Icon';

const styles = {
  formControl: {
    width: '100%'
  },
  error: {
    border: '1px solid red'
  }
};

class PasswordField extends React.Component {
  state = {
    showPassword: false
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { classes, label, value, onChange, errors, handleKeyDown, ...rest } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel>{label}</InputLabel>
        <Input
          autoCapitalize="off"
          error={errors}
          type={this.state.showPassword ? 'text' : 'password'}
          value={value || ''}
          onChange={e => onChange(e)}
          onKeyDown={handleKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={this.handleClickShowPassword}>
                {this.state.showPassword ? (
                  <Icon type="eye_blue" width={29} height={20} />
                ) : (
                  <Icon type="eye_gray" width={29} height={20} />
                )}
              </IconButton>
            </InputAdornment>
          }
          {...rest}
        />
      </FormControl>
    );
  }
}

export default withStyles(styles)(PasswordField);
