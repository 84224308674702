import { combineReducers } from 'redux';
import app from './app';
import login from './login';
import busyIndicator from './busyIndicator';
import account from './account';
import parkingLot from './parkingLot';
import lang from './lang';
import myParkingSlots from './myParkingSlots';
import bookings from './bookings';
import availableParkingSlots from './availableParkingSlots';
import availability from './parkingSlotAvailability';
import userManagement from './userManagement';
import bookingManagement from './bookingManagement';
import ownerBookings from './ownerBookings';
import map from './map';
import transactionReport from './transactionReport';
import accessControlManagement from './accessControlManagement.js';
import accessReport from './accessReport.js';

export const rootReducer = combineReducers({
  app,
  login,
  busyIndicator,
  account,
  parkingLot,
  bookings,
  lang,
  parkingSlots: combineReducers({
    mine: myParkingSlots,
    available: availableParkingSlots
  }),
  availability,
  userManagement,
  ownerBookings,
  bookingManagement,
  map,
  transactionReport,
  accessControlManagement,
  accessReport
});
