import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { text1 } from '@config/colors';
import Spinner from '@components/Spinner';

const styles = {
  busy: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 9999999999
  },
  app: {
    background: text1(0)
  },
  admin: {
    background: text1(0.1)
  }
};

function BusyIndicator({ classes }) {
  const busyIndicator = useSelector(state => state.busyIndicator);

  if (busyIndicator.counter === 0) {
    return null;
  }
  switch (busyIndicator.mode) {
    case 'app':
      return <div className={classNames(classes.busy, classes.app)}></div>;
    case 'admin':
      return <Spinner className={classNames(classes.busy, classes.admin)} />;
    default:
      return null;
  }
}

export default withStyles(styles)(BusyIndicator);
