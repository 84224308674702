import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import BusyIndicator from '@components/BusyIndicator';
import muiTheme from '@config/mui-theme';
import LoginPage from '@containers/LoginPage';
import SignupPage from '@containers/SignupPage';
import DialogsContainer from '@dialogs/DialogsContainer';
import ResetPasswordPage from '@pages/ResetPasswordPage';
import AuthenticateChargePage from '@pages/AuthenticateCharge/AuthenticateChargePage';
import { dispatch } from '@redux/store';
import { setLoggedOut } from '@redux/login';
import { getUser } from '@utils/auth';
import { hideSplashScreen } from '@utils/utils';
import { keyboardStateService } from '@utils/KeyboardStateService';
import MainPageFetcher from './MainPageFetcher';

const theme = createMuiTheme(muiTheme);
const wrapperS = { height: '100%', width: '100%' };

const mapStateToProps = state => ({
  login: state.login
});

class App extends Component {
  state = {
    showApp: false
  };

  componentDidMount() {
    if ('cordova' in window) {
      universalLinks.subscribe(null, event => {
        const url = new URL(event.url);
        if (url.hash === '#/signup') {
          // TODO legacy
          this.props.history.push('/signup/' + url.searchParams.get('registrationToken'));
          return;
        }
        this.props.history.push(url.hash.substring(1) + url.search);
        this.checkLocation();
      });
    }

    keyboardStateService.init();
    this.checkLocation();

    getUser()
      .catch(() => null)
      .then(() => {
        this.setState({ showApp: true }, () => {
          hideSplashScreen();
        });
      });
  }

  componentWillUnmount() {
    keyboardStateService.destroy();
  }

  // TODO remove: legacy for supporting emails with old links
  checkLocation() {
    const params = new URLSearchParams(location.search);
    const returningFlow = params.get('flow');

    if (returningFlow) {
      const token = params.get('token');
      const lang = params.get('lang');
      this.handleReturningFlow(returningFlow, token, lang);
    }
  }

  handleReturningFlow(flow, token, lang) {
    window.history.replaceState({}, document.title, '/'); // remove all query params
    switch (flow) {
      case 'resetPassword':
        dispatch(setLoggedOut());
        this.props.history.push(`/resetPassword/${token}/${lang}`);
        break;
      case 'authenticateCharge':
        dispatch(setLoggedOut());
        this.props.history.push(`/authenticateCharge/${token}/${lang}`);
        break;
      case 'signup':
        dispatch(setLoggedOut());
        this.props.history.push(`/signup/${token}`);
    }
  }

  mainPageWithResolver = () => {
    const { loggedIn, user } = this.props.login;
    return loggedIn ? <MainPageFetcher role={user.role} /> : <Redirect to="/login" />;
  };

  render() {
    if (!this.state.showApp) {
      return null;
    }
    return (
      <div style={wrapperS}>
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route path="/login/:token?/:lang?" component={LoginPage} />
            <Route path="/signup/:token?" component={SignupPage} />
            <Route path="/resetPassword/:token/:lang?" component={ResetPasswordPage} />
            <Route path="/authenticateCharge/:token/:lang?" component={AuthenticateChargePage} />
            <Route path="/sys" render={this.mainPageWithResolver} />
            <Redirect exact from="/" to="/login" />
          </Switch>
          <BusyIndicator />
          <DialogsContainer />
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(App));
