import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import MuiTextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  formControl: {
    width: '100%'
  },
  error: {
    border: '1px solid red'
  },
  labelRoot: {
    '&$erroredLabel': {
      color: '#00719e'
    }
  },
  erroredLabel: {
    color: '#00719e'
  }
});

class TextField extends React.Component {
  render() {
    const { classes, value, label, onChange, errors, disabled, type, ...rest } = this.props;
    return (
      <FormControl className={classes.formControl} variant="outlined">
        <MuiTextField
          error={errors}
          className={classes.textField}
          value={value || ''}
          label={label}
          type={type || 'text'}
          margin="normal"
          disabled={disabled}
          inputProps={{
            autoCapitalize: 'off'
          }}
          InputLabelProps={{ classes: { root: classes.labelRoot, error: classes.erroredLabel } }}
          onChange={e => onChange(e)}
          {...rest}
        />
      </FormControl>
    );
  }
}

export default withStyles(styles)(TextField);
