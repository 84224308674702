import { createAction, handleActions } from 'redux-actions';

export const actions = {
  startFetching: createAction('[userManagement] START_FETCHING'),
  doneFetching: createAction('[userManagement] DONE_FETCHING'),
  setSearchMail: createAction('[userManagement] SET_SEARCH_MAIL'),
  updateData: createAction('[userManagement] UPDATE_DATA')
};

const initialState = {
  lastUpdate: null, // timestamp.
  isFetching: false, // Are we currently fetching users.
  currentPage: 0, // The current users page (for paging).
  searchMail: '', // The mail address to search for
  isLastPage: false, // Is the current page the last page.
  count: 0, // The total amount of users.
  data: [] // Array of users.
};

export default handleActions(
  {
    [actions.startFetching]: state => ({ ...state, isFetching: true }),

    [actions.doneFetching]: state => ({ ...state, isFetching: false }),

    [actions.setSearchMail]: (state, { payload }) => ({ ...state, searchMail: payload }),

    [actions.updateData]: (state, { payload: { pageNum, data, count, isLastPage } }) => ({
      ...state,
      lastUpdate: Date.now(),
      currentPage: pageNum,
      isLastPage,
      count,
      data
    })
  },
  initialState
);
