import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@components/TextField';
import RadioField from '@components/RadioField';
import Button from '@components/Button';
import LangButton from '@components/LangButton';
import PasswordField from '@components/PasswordField';
import FieldError from '@components/FieldError';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import BottomSection from '@components/BottomSection';
import * as colors from '@config/colors';
import { api } from '@utils/data';
import { validations } from '@utils/validations';
import { initLogin } from '@utils/auth';
import dialogsManager from '@dialogs/DialogsManager';

const styles = {
  wrapper: {
    height: '100%',
    margin: 'auto',
    position: 'relative'
  },
  scrollableWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 1
  },
  topSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10
  },
  backBtn: {
    display: 'flex',
    alignItems: 'center'
  },
  loginLinkText: {
    color: colors.blue2,
    fontSize: 18,
    marginLeft: 5
  },
  form: {
    marginTop: 30,
    width: '300px',
    margin: 'auto'
  }
};

const options = [
  { id: 'PARKING_OWNER', value: <I18n>signup.parkingOwner</I18n> },
  { id: 'PARKING_CUSTOMER', value: <I18n>signup.parkingCustomer</I18n> }
];

const mapStateToProps = state => ({
  login: state.login,
  lang: state.lang
});

class SignupPage extends Component {
  state = {
    role: 'PARKING_CUSTOMER',
    email: '',
    totalErrors: null,
    errors: {},
    touched: {
      email: false,
      password: false,
      confirmPassword: false
    },
    awaitingConfirmation: false
  };

  possibleDomains = [];

  fetchInformation() {
    const regToken = this.props.match.params.token;
    if (regToken) {
      api({
        type: 'decodeAuthRegToken',
        data: { regToken }
      }).then(res => {
        this.setState({
          email: res.email,
          role: res.role,
          invitedByAdmin: true,
          token: regToken
        });
      });
    }
  }

  componentDidMount() {
    api({ type: 'getAllowedDomains' }).then(res => {
      this.possibleDomains = res.domains;
    });
    this.fetchInformation();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.token !== prevProps.match.params.token) {
      this.fetchInformation();
    }
  }

  validate = (name, value) => {
    let errors = [];
    switch (name) {
      case 'email':
        if (validations['required'](value)) errors.push({ errKey: 'error.required' });
        if (validations['email'](value)) errors.push({ errKey: 'error.invalidEmail' });
        if (!this.state.invitedByAdmin) {
          if (validations['emailDomain'](value, this.possibleDomains))
            errors.push({ errKey: 'error.invalidEmailDomain' });
        }
        break;
      case 'password':
        if (validations['required'](value)) errors.push({ errKey: 'error.required' });
        if (validations['minLength'](value, 6)) errors.push({ errKey: 'signup.invalidPassword' });
        if (validations['maxLength'](value, 20)) errors.push({ errKey: 'signup.invalidPassword' });
        if (validations['regex'](value, /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]/))
          errors.push({ errKey: 'error.lettersAndNumber' });
        break;
      case 'confirmPassword':
        if (validations['required'](value)) errors.push({ errKey: 'error.required' });
        if (validations['confirmPassword'](value, this.state['password']))
          errors.push({ errKey: 'error.passwordsDontMatch' });
        break;
    }
    return errors;
  };

  sumErrors = () => {
    let total = 0;
    Object.entries(this.state.errors).forEach(fieldError => {
      total += fieldError[1].length;
    });
    return total;
  };

  handleChange(name, event) {
    this.state[name] = event.target.value;
    this.state.errors[name] = this.validate(name, event.target.value);

    if (name === 'password') {
      this.state.errors['confirmPassword'] = this.validate(
        'confirmPassword',
        this.state['confirmPassword']
      );
    }

    this.setState({
      ...this.state,
      totalErrors: this.sumErrors()
    });
  }

  signup = event => {
    event.preventDefault();
    api({
      type: 'signup',
      data: {
        token: this.state.token,
        email: this.state.email,
        password: this.state.password,
        role: [this.state.role],
        language: this.props.lang.selectedLang
      }
    }).then(async res => {
      if (res.errKey === 'error.confirmRegistration') {
        await dialogsManager.openAlert({
          text: res.errKey
        });
        this.setState({ awaitingConfirmation: true });
      } else {
        return initLogin(res);
      }
    });
  };

  setTouched(field) {
    if (!this.state.touched[field]) {
      this.setState({
        touched: {
          ...this.state.touched,
          [field]: true
        }
      });
    }
  }

  hasError(field) {
    return (this.state.errors[field] || '').length > 0;
  }

  isPristine(field) {
    return this.state[field] === undefined;
  }

  render() {
    const {
      classes,
      login: {
        loggedIn,
        permissions: { defaultRoute }
      }
    } = this.props;
    const { touched, errors, totalErrors, awaitingConfirmation } = this.state;
    const usernameErr = touched.email && this.hasError('email');
    const passwordErr = touched.password && this.hasError('password');
    const confirmPasswordErr = touched.confirmPassword && this.hasError('confirmPassword');
    const signupBtnDisabled =
      this.isPristine('email') ||
      this.isPristine('password') ||
      this.isPristine('confirmPassword') ||
      totalErrors > 0;

    if (awaitingConfirmation) {
      return <Redirect to="/" />;
    }

    if (loggedIn && defaultRoute) {
      return <Redirect to={defaultRoute} />;
    }

    return (
      <div className={classes.wrapper}>
        <div className={classNames(classes.scrollableWrapper, 'scrollable-main')}>
          <div>
            <div className={classes.topSection}>
              <Link to="/login">
                <div className={classes.backBtn}>
                  <Icon type="left_icon_blue" width={25} height={25} />
                  <I18n className={classes.loginLinkText}>G.login</I18n>
                </div>
              </Link>
              <LangButton />
            </div>
            <Icon type="logo" width={204} height={81.5} />
          </div>
          <form className={classes.form} onSubmit={this.signup}>
            <div style={{ marginTop: 10 }}>
              <TextField
                type="email"
                errors={usernameErr}
                label={<I18n>G.email</I18n>}
                value={this.state['email']}
                disabled={this.state.invitedByAdmin}
                onChange={e => this.handleChange('email', e)}
                onBlur={() => this.setTouched('email')}
              />
              {usernameErr && <FieldError errors={errors['email'][0]} />}
            </div>
            <div style={{ marginTop: 10 }}>
              <PasswordField
                errors={passwordErr}
                label={<I18n>G.password</I18n>}
                value={this.state['password']}
                onChange={e => this.handleChange('password', e)}
                onBlur={() => this.setTouched('password')}
              />
              {passwordErr && <FieldError errors={errors['password'][0]} />}
            </div>
            <div style={{ marginTop: 10 }}>
              <PasswordField
                errors={confirmPasswordErr}
                label={<I18n>signup.confirmPassword</I18n>}
                value={this.state['confirmPassword']}
                onChange={e => this.handleChange('confirmPassword', e)}
                onBlur={() => this.setTouched('confirmPassword')}
              />
              {confirmPasswordErr && <FieldError errors={errors['confirmPassword'][0]} />}
            </div>
            <div style={{ marginTop: 20 }}>
              <RadioField
                value={this.state['role']}
                options={options}
                disabled={this.state.invitedByAdmin}
                onChange={e => this.handleChange('role', e)}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <Button
                label={<I18n>G.signup</I18n>}
                type="submit"
                style={{ width: '100%' }}
                disabled={signupBtnDisabled}
              />
            </div>
          </form>
        </div>
        <BottomSection />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(SignupPage)));
