import React from 'react';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

const styles = {
  wrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

function Spinner({ classes, className, style, props }) {
  return (
    <div className={classNames(classes.wrapper, className)} style={style}>
      <CircularProgress disableShrink {...props} />
    </div>
  );
}

export default withStyles(styles)(Spinner);
