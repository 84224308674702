import { useEffect, useRef } from 'react';
import { getIn } from 'formik';

/**
 * Saves the previous value of a prop / state variable.
 * Uses a mutable ref for this purpose.
 * See: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * Used in formik custom components to validate their value on mount.
 */
export function validateOnMount(formik, name) {
  useEffect(() => {
    // This will trigger form validation on mount.
    formik.setFieldValue(name, getIn(formik.values, name));
  }, []);
}

/**
 * Used in formik custom components to register a form-level validator.
 */
export function registerFormikValidate(formik, name, validate) {
  useEffect(() => {
    if (validate) {
      formik.registerField(name, { props: { validate } });
    }
    return () => {
      if (validate) {
        formik.unregisterField(name);
      }
    };
  }, [formik, name, validate]);
}
