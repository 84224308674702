import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import I18n from '@components/I18n';
import PasswordField from '@components/PasswordField';
import FieldError from '@components/FieldError';
import Button from '@components/Button';
import Icon from '@components/Icon';
import LangButton from '@components/LangButton';
import * as colors from '@config/colors';
import dialogsManager from '@dialogs/DialogsManager';
import { validations } from '@utils/validations';
import { api } from '@utils/data';
import { changeLanguage } from '@utils/i18nService';

const styles = {
  wrapper: {
    height: '100%',
    margin: 'auto',
    position: 'relative'
  },
  topSection: {
    textAlign: 'right',
    position: 'relative',
    padding: 10
  },
  title: {
    fontSize: 22,
    textAlign: 'center',
    marginTop: 63,
    color: colors.blue1
  },
  form: {
    marginTop: 60,
    width: 300,
    margin: 'auto',
    position: 'relative'
  },
  field: {
    marginTop: 10
  },
  btnWrapper: {
    marginTop: 46.5
  },
  submitBtn: {
    width: '100%',
    height: '53'
  },
  backToLogin: {
    color: colors.blue2,
    fontSize: 15,
    marginTop: 34,
    textAlign: 'center'
  }
};

const ResetPasswordPage = props => {
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [errors, setErrors] = useState({ newPassword: null, confirmPassword: null });
  const [touched, setTouched] = useState({ newPassword: false, confirmPassword: false });
  const [isValidForm, setIsValidForm] = useState(false);
  const [token, setToken] = useState(null);
  const { history, match, classes } = props;

  useEffect(() => {
    if (match.params.lang && match.params.lang !== 'null') {
      changeLanguage(match.params.lang);
    }

    const encodedToken = match.params.token;
    if (!encodedToken) {
      history.push('/');
      return;
    }
    setToken(encodedToken);

    api({
      type: 'isValidPasswordResetToken',
      data: { token: encodedToken }
    }).then(res => {
      if (!res.valid) {
        dialogsManager
          .openAlert({
            text: 'error.linkExpired',
            success: false,
            overrideButtonLabels: { ok: 'resetPassword.backToLogin' }
          })
          .then(() => {
            history.push('/');
          });
      }
    });
  }, []);

  const onValueChanges = (key, event) => {
    const value = event.target.value;
    let newErrors = { ...errors };
    switch (key) {
      case 'newPassword':
        setNewPassword(value);
        newErrors.newPassword = validatePassword(value);
        newErrors.confirmPassword = validateConfirmPassword(confirmPassword, value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        newErrors.confirmPassword = validateConfirmPassword(value, newPassword);
        break;
    }
    setErrors(newErrors);
    setIsValidForm(newErrors.newPassword === null && newErrors.confirmPassword === null);
  };

  const onBlur = key => {
    setTouched({ ...touched, [key]: true });
  };

  const validatePassword = val => {
    if (validations['required'](val)) {
      return { errKey: 'error.required' };
    }
    if (validations['minLength'](val, 6)) {
      return { errKey: 'signup.invalidPassword' };
    }
    if (validations['maxLength'](val, 20)) {
      return { errKey: 'signup.invalidPassword' };
    }
    if (validations['regex'](val, /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]/)) {
      return { errKey: 'error.lettersAndNumber' };
    }
    return null;
  };

  const validateConfirmPassword = (val, newPassword) => {
    if (validations['required'](val)) {
      return { errKey: 'error.required' };
    }
    if (validations['confirmPassword'](val, newPassword)) {
      return { errKey: 'error.passwordsDontMatch' };
    }
    return null;
  };

  const onSubmit = event => {
    event.preventDefault();
    api({
      type: 'resetPassword',
      data: { token, newPassword }
    }).then(
      res => {
        dialogsManager
          .openAlert({ text: 'resetPassword.successDialog', success: true })
          .then(() => {
            history.push('/login');
          });
      },
      err => {
        dialogsManager.openAlert({ text: err.data.errKey, success: false });
      }
    );
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.topSection}>
        <LangButton />
      </div>
      <Icon type="logo" width={112} height={45} />
      <div className={classes.title}>
        <I18n component="div" className={classes.title}>
          resetPassword.title
        </I18n>
      </div>
      <form onSubmit={onSubmit} className={classes.form}>
        <div className={classes.field}>
          <PasswordField
            errors={touched.newPassword && errors.newPassword !== null}
            label={<I18n>G.password</I18n>}
            value={newPassword}
            onChange={event => onValueChanges('newPassword', event)}
            onBlur={() => onBlur('newPassword')}
          />
          {errors.newPassword !== null && touched.newPassword && (
            <FieldError errors={errors.newPassword} />
          )}
        </div>
        <div className={classes.field}>
          <PasswordField
            errors={touched.confirmPassword && errors.confirmPassword !== null}
            label={<I18n>G.confirmPassword</I18n>}
            value={confirmPassword}
            onChange={event => onValueChanges('confirmPassword', event)}
            onBlur={() => onBlur('confirmPassword')}
          />
          {errors.confirmPassword !== null && touched.confirmPassword && (
            <FieldError errors={errors.confirmPassword} />
          )}
        </div>
        <div className={classes.btnWrapper}>
          <Button
            classes={{ button: classes.submitBtn }}
            type="submit"
            color="primary"
            label={<I18n>G.done</I18n>}
            disabled={!isValidForm}
          />
        </div>
        <div className={classes.backToLogin}>
          <Link to="/login" className={classes.backToLogin}>
            {<I18n>resetPassword.backToLogin</I18n>}
          </Link>
        </div>
      </form>
    </div>
  );
};

export default withRouter(withStyles(styles)(ResetPasswordPage));
