export const permissions = {
  PARKING_OWNER: {
    defaultRoute: '/sys/my-parking-spaces',
    menuItems: [
      { label: 'pageTitles.profile', route: '/sys/profile' },
      { label: 'pageTitles.myParkingSpaces', route: '/sys/my-parking-spaces' },
      {
        label: 'pageTitles.overview',
        route: '/sys/owner-bookings',
        headerLabel: 'pageHeader.overview'
      }
    ]
  },
  PARKING_CUSTOMER: {
    defaultRoute: '/sys/my-bookings',
    menuItems: [
      { label: 'pageTitles.profile', route: '/sys/profile' },
      { label: 'pageTitles.myBookings', route: '/sys/my-bookings' }
    ]
  },
  ACCESS_CONTROL_ADMIN: {
    defaultRoute: '/sys/access-control',
    menuItems: [
      { label: 'pageTitles.accessControl', route: '/sys/access-control' },
      { label: 'pageTitles.accessReport', route: '/sys/access-report' }
    ]
  },
  ACCOUNT_ADMIN: {
    defaultRoute: '/sys/user-management',
    menuItems: [
      { label: 'pageTitles.userManagement', route: '/sys/user-management' },
      { label: 'pageTitles.bookingManagement', route: '/sys/booking-management' }
    ]
  },
  SUPER_ADMIN: {
    defaultRoute: '/sys/transaction-report',
    menuItems: [{ label: 'pageTitles.transactionReport', route: '/sys/transaction-report' }]
  }
};
