const env = {
  prod: {
    env: 'prod',
    baseUrl: 'https://booking.alpha-standards.com',
    domain: 'booking.alpha-standards.com',
    mail: {
      from: 'info@alpha-standards.com',
      from2: 'service@alpha-standards.com',
      errorsRecepients: ['info@alpha-standards.com', 'marian@joetabu.de'],
      apiKey: process.env.SENDGRID_API_KEY
    },
    mongo: {
      url: process.env.MONGO_HOST,
      dbName: process.env.MONGO_DB_NAME
    },
    cloudinary: {
      email: 'alpha.booking.dev@gmail.com',
      password: 'Commit123!'
    },
    google: {
      username: 'alpha.booking.dev',
      password: 'commit123'
    },
    stripe: {
      publishableKey: 'pk_live_uIBmAVubbi5CwW5fz0pMames00PdHqDske',
      secretKey: process.env.STRIPE_SECRET_KEY,
      endpointSecret: process.env.STRIPE_ENDPOINT_SECRET
    },
    jsonWebTokenSecret: process.env.JSONWEBTOKEN_SECRET
  },

  qa: {
    env: 'qa',
    baseUrl: 'https://alphabooking-qa.herokuapp.com',
    domain: 'alphabooking-qa.herokuapp.com',
    mail: {
      from: 'info@alpha-standards.com',
      from2: 'service@alpha-standards.com',
      errorsRecepients: ['marian@joetabu.de'],
      apiKey: process.env.SENDGRID_API_KEY
    },
    mongo: {
      url: process.env.MONGO_HOST,
      dbName: process.env.MONGO_DB_NAME
    },
    cloudinary: {
      email: 'alpha.booking.dev@gmail.com',
      password: 'Commit123!'
    },
    google: {
      username: 'alpha.booking.dev',
      password: 'commit123'
    },
    stripe: {
      publishableKey: 'pk_test_xIVDNVEXkncVJ10JoJMf0dn900rkeX8Lvz',
      secretKey: process.env.STRIPE_SECRET_KEY,
      endpointSecret: process.env.STRIPE_ENDPOINT_SECRET
    },
    jsonWebTokenSecret: process.env.JSONWEBTOKEN_SECRET
  },

  dev3: {
    env: 'dev3',
    baseUrl: 'https://alphabooking-dev3.herokuapp.com',
    domain: 'alphabooking-dev3.herokuapp.com',
    mail: {
      from: 'info@alpha-standards.com',
      from2: 'service@alpha-standards.com',
      errorsRecepients: ['marian@joetabu.de'],
      apiKey: process.env.SENDGRID_API_KEY
    },
    mongo: {
      url: process.env.MONGO_HOST,
      dbName: process.env.MONGO_DB_NAME
    },
    cloudinary: {
      email: 'alpha.booking.dev@gmail.com',
      password: 'Commit123!'
    },
    google: {
      username: 'alpha.booking.dev',
      password: 'commit123'
    },
    stripe: {
      publishableKey: 'pk_test_xIVDNVEXkncVJ10JoJMf0dn900rkeX8Lvz',
      secretKey: process.env.STRIPE_SECRET_KEY,
      endpointSecret: process.env.STRIPE_ENDPOINT_SECRET
    },
    jsonWebTokenSecret: process.env.JSONWEBTOKEN_SECRET
  },

  staging: {
    env: 'staging',
    baseUrl: 'https://alphabooking-staging.herokuapp.com',
    domain: 'alphabooking-staging.herokuapp.com',
    mail: {
      from: 'info@alpha-standards.com',
      from2: 'service@alpha-standards.com',
      errorsRecepients: ['marian@joetabu.de'],
      apiKey: process.env.SENDGRID_API_KEY
    },
    mongo: {
      url: process.env.MONGO_HOST,
      dbName: process.env.MONGO_DB_NAME,
      sourceUrl: process.env.MONGO_SOURCE_HOST,
      sourceDbName: process.env.MONGO_SOURCE_DB_NAME
    },
    cloudinary: {
      email: 'alpha.booking.dev@gmail.com',
      password: 'Commit123!'
    },
    google: {
      username: 'alpha.booking.dev',
      password: 'commit123'
    },
    stripe: {
      publishableKey: 'pk_test_xIVDNVEXkncVJ10JoJMf0dn900rkeX8Lvz',
      secretKey: process.env.STRIPE_SECRET_KEY,
      endpointSecret: process.env.STRIPE_ENDPOINT_SECRET
    },
    jsonWebTokenSecret: process.env.JSONWEBTOKEN_SECRET
  },

  dev: {
    env: 'dev',
    baseUrl: 'http://localhost:3000',
    mail: {
      from: 'info-dev@alpha-standards.com',
      from2: 'service-dev@alpha-standards.com',
      errorsRecepients: ['yanivby@gmail.com'],
      //apiKey: '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'
      apiKey: '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'
    },
    mongo: {
      url: 'mongodb://localhost:27017',
      dbName: 'alphabooking'
      // unmark those lones only if you'd like to clone QA db to your local DB
      // sourceUrl: 'mongodb://zibi:commit123@ds145434.mlab.com:45434',
      // sourceDbName: 'heroku_74p46hrx',
    },
    // mongo: {
    //   url: 'mongodb://zibi:commit123@ds145434.mlab.com:45434',
    //   dbName: 'heroku_74p46hrx',
    // },
    cloudinary: {
      email: 'alpha.booking.dev@gmail.com',
      password: 'Commit123!'
    },
    google: {
      username: 'alpha.booking.dev',
      password: 'commit123'
    },
    stripe: {
      publishableKey: 'pk_test_xIVDNVEXkncVJ10JoJMf0dn900rkeX8Lvz',
      secretKey: 'sk_test_aHbnR4qN0gDsv7ZtOGUGRF1p00TohmtSzz',
      endpointSecret: ''
    },
    jsonWebTokenSecret: 'dGhpcyBpcyBhIHRlc3QgdG9rZW4='
  }
};

function getEnvType() {
  if (process.env.NODE_ENV !== 'production') {
    // Local development.
    return 'dev';
  } else if (process.env.ALPHA_ENV === 'qa') {
    // Cloud QA host.
    return 'qa';
  } else if (process.env.ALPHA_ENV === 'dev3') {
    return 'dev3';
  } else if (process.env.ALPHA_ENV === 'prod') {
    // Cloud production host.
    return 'prod';
  } else if (process.env.ALPHA_ENV === 'staging') {
    // Cloud staging host.
    return 'staging';
  } else {
    throw new Error('Unrecognized env type. Please set ALPHA_ENV environment variable!');
  }
}

module.exports = env[getEnvType()];
