import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import classNames from 'classnames';
import React, { Component } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

const styles = theme => ({
  button: {
    margin: 'unset',
    background: 'linear-gradient(to right, #0379be, #00a2e2)',
    color: 'white',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 'unset',
    '&:disabled': {
      opacity: 0.5,
      color: '#fff'
    }
  }
});

function withSubmit(WrappedComponent) {
  return class extends Component {
    render() {
      const { onClick, disabled } = this.props;
      return (
        <React.Fragment>
          {this.props.submitOnEnter && (
            <KeyboardEventHandler
              handleKeys={['enter']}
              onKeyEvent={() => !disabled && onClick && onClick()}
            />
          )}
          <WrappedComponent {...this.props} />
        </React.Fragment>
      );
    }
  };
}

class Button extends Component {
  render() {
    const {
      classes,
      label,
      onClick,
      style,
      disabled,
      type,
      component,
      target,
      href,
      disableRipple,
      className
    } = this.props;
    return (
      <React.Fragment>
        <MuiButton
          variant="contained"
          className={classNames(classes.button, className)}
          disabled={disabled}
          style={style}
          onClick={onClick}
          component={component}
          disableRipple={disableRipple}
          target={target}
          href={href}
          type={type || 'button'}>
          {label}
        </MuiButton>
      </React.Fragment>
    );
  }
}

Button.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSubmit(withStyles(styles)(Button));
