import React, { Suspense } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';

const MainPage = React.lazy(() => import('../containers/MainPage'));
const AdminMainPage = React.lazy(() => import('../containers/AdminMainPage'));

const styles = {
  loading: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

function MainPageFetcher({ role, classes }) {
  const fallback = (
    <div className={classes.loading}>
      <CircularProgress disableShrink />
    </div>
  );
  const isAdmin =
    role.includes('ACCOUNT_ADMIN') ||
    role.includes('SUPER_ADMIN') ||
    role.includes('ACCESS_CONTROL_ADMIN');
  return <Suspense fallback={fallback}>{isAdmin ? <AdminMainPage /> : <MainPage />}</Suspense>;
}

export default withStyles(styles)(MainPageFetcher);
