import { createAction, handleActions } from 'redux-actions';

export const actions = {
  startFetching: createAction('[parkingSlotAvailability] START_FETCHING'),
  doneFetching: createAction('[parkingSlotAvailability] DONE_FETCHING'),
  updateData: createAction('[parkingSlotAvailability] UPDATE_DATA'),
  removeById: createAction('[parkingSlotAvailability] REMOVE_BY_ID')
};

/*
The state will look like this:
{
  [parkingSlotId]: {
    [month(YYYY-MM)]: {
      lastUpdate: null,  // timestamp.
      isFetching: false, // Are we currently fetching this data.
      data: [],  // Array of parkingSlotAvailabilities: { id: string, dayInMonth: number, isOccupied: boolean }
    }
  }
}
*/
const initialState = {};

export default handleActions(
  {
    [actions.startFetching]: (state, { payload }) => {
      const { parkingSlotId, month } = payload;
      return immutableEdit(state, parkingSlotId, month, () => ({
        isFetching: true
      }));
    },

    [actions.doneFetching]: (state, { payload }) => {
      const { parkingSlotId, month } = payload;
      return immutableEdit(state, parkingSlotId, month, () => ({
        isFetching: false
      }));
    },

    [actions.updateData]: (state, { payload }) => {
      const { parkingSlotId, month, data } = payload;
      return immutableEdit(state, parkingSlotId, month, () => ({
        lastUpdate: Date.now(),
        data
      }));
    },

    [actions.removeById]: (state, { payload }) => {
      const { parkingSlotId, month, parkingSlotAvailabilityId } = payload;
      return immutableEdit(state, parkingSlotId, month, item => ({
        data: item.data.filter(item => item.id !== parkingSlotAvailabilityId)
      }));
    }
  },
  initialState
);

function immutableEdit(state, parkingSlotId, month, editFn) {
  // Init data if it doesn't exist.
  if (!state[parkingSlotId]) {
    state[parkingSlotId] = {};
  }
  if (!state[parkingSlotId][month]) {
    state[parkingSlotId][month] = NullItem();
  }

  // Perform an immutable edit for an item on the state.
  return {
    ...state,
    [parkingSlotId]: {
      ...state[parkingSlotId],
      [month]: {
        ...state[parkingSlotId][month],
        ...editFn(state[parkingSlotId][month])
      }
    }
  };
}

function NullItem() {
  return {
    lastUpdate: null,
    isFetching: false,
    data: []
  };
}
