import { createAction, handleActions } from 'redux-actions';

export const clearFilters = createAction('[availableParkingSlots] CLEAR_FILTERS');
export const clear = createAction('[availableParkingSlots] CLEAR');
export const startFetching = createAction('[availableParkingSlots] START_FETCHING');
export const doneFetching = createAction('[availableParkingSlots] DONE_FETCHING');
export const updateData = createAction('[availableParkingSlots] UPDATE_DATA');
export const setFilters = createAction('[availableParkingSlots] SET_FILTERS');
export const fetchNextPage = createAction('[availableParkingSlots] FETCH_NEXT_PAGE');

const initialState = {
  lastUpdate: null, // timestamp.
  isFetching: false, // Are we currently fetching parking-slots.
  data: [], // Array of parking-slots.
  filters: {
    levelIds: [],
    areaIds: []
  },
  filteredData: [], // Array of parking-slots, with filters applied.
  displayData: [],
  displayPage: null,
  displayHasNextPage: null
};

const ITEMS_IN_PAGE = 50;

const hasNextPage = (filterData, page) => {
  return page * ITEMS_IN_PAGE < filterData.length;
};

export default handleActions(
  {
    [startFetching]: state => ({ ...state, isFetching: true }),

    [doneFetching]: state => ({ ...state, isFetching: false }),

    [updateData]: (state, { payload }) => {
      const filteredData = filterData(payload, state.filters) || [];
      return {
        ...state,
        lastUpdate: Date.now(),
        data: payload,
        filteredData,
        displayData: filteredData.concat().splice(0, ITEMS_IN_PAGE),
        displayPage: 1,
        displayHasNextPage: hasNextPage(filteredData, 1)
      };
    },

    [setFilters]: (state, { payload }) => {
      const filteredData = filterData(state.data, payload) || [];
      return {
        ...state,
        filters: {
          levelIds: [...payload.levelIds],
          areaIds: [...payload.areaIds]
        },
        filteredData,
        displayData: filteredData.concat().splice(0, ITEMS_IN_PAGE),
        displayPage: 1,
        displayHasNextPage: hasNextPage(filteredData, 1)
      };
    },

    [fetchNextPage]: state => {
      if (!state.displayHasNextPage) return { ...state };
      const nextPage = state.displayPage + 1;
      return {
        ...state,
        displayData: state.displayData.concat(
          state.filteredData.concat().splice(state.displayPage * ITEMS_IN_PAGE, ITEMS_IN_PAGE)
        ),
        displayPage: nextPage,
        displayHasNextPage: hasNextPage(state.filteredData, nextPage)
      };
    },

    [clearFilters]: state => ({
      ...state,
      filteredData: [...state.data],
      filters: {
        levelIds: [],
        areaIds: []
      }
    }),

    [clear]: () => initialState
  },
  initialState
);

function filterData(data, filters) {
  if (!filters.areaIds.length && !filters.levelIds.length && !filters.searchText) {
    return data;
  }
  return data.filter(item => {
    const passedAreaFilter = filters.areaIds.length ? filters.areaIds.includes(item.area.id) : true;

    const passedLevelFilter = filters.levelIds.length
      ? filters.levelIds.includes(item.floor.id)
      : true;

    const passedSearchFilter =
      filters.searchText && filters.searchText.length
        ? item.name.includes(filters.searchText)
        : true;

    return passedAreaFilter && passedLevelFilter && passedSearchFilter;
  });
}
