import { createAction, handleActions } from 'redux-actions';

export const startFetching = createAction('[accessReport] START_FETCHING');
export const doneFetching = createAction('[accessReport] DONE_FETCHING');
export const setData = createAction('[accessReport] SET_DATA');
export const addPage = createAction('[accessReport] ADD_PAGE');
export const setSelectedDate = createAction('[accessReport] SET_SELECTED_DATE');
export const setSearchTerm = createAction('[accessReport] SET_SEARCH_TERM');
export const setDateOf1stEvent = createAction('[accessReport] SET_DATE_OF_1ST_EVENT');

const initialState = {
  dateOf1stEvent: null,
  data: null,
  currentPage: null,
  isLastPage: null,
  total: null,
  isLoadingData: false,
  isPayingOwners: false,
  selectedDate: null,
  searchTerm: ''
};

export default handleActions(
  {
    [startFetching]: state => {
      return { ...state, isLoadingData: true };
    },

    [doneFetching]: state => {
      return { ...state, isLoadingData: false };
    },

    /**
     * Sets the 1st page.
     */
    [setData]: (state, { payload: { currentPage, isLastPage, total, data } }) => {
      return {
        ...state,
        total,
        isLastPage,
        currentPage,
        data
      };
    },

    /**
     * Adds a page to the data.
     */
    [addPage]: (state, { payload: { currentPage, isLastPage, total, data } }) => {
      if (!data) {
        throw new Error('Cannot addPage when no 1st page exists!');
      }
      return {
        ...state,
        total,
        isLastPage,
        currentPage,
        data: [...state.data, ...data]
      };
    },

    [setSelectedDate]: (state, { payload }) => {
      return {
        ...state,
        selectedDate: payload
      };
    },

    [setSearchTerm]: (state, { payload }) => {
      return {
        ...state,
        searchTerm: payload
      };
    },

    [setDateOf1stEvent]: (state, { payload }) => {
      return {
        ...state,
        dateOf1stEvent: payload
      };
    }
  },
  initialState
);
