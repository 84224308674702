import React, { useState, useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { cerulean } from '@config/colors';
import layout from '@config/layout';
import LangButton from './LangButton';
import SideMenu from './SideMenu';
import I18n from './I18n';

const styles = {
  header: {
    boxShadow: 'none',
    height: layout.HEADER_HEIGHT
  },
  root: {
    background: cerulean,
    color: 'white',
    height: '100%'
  },
  menuBtn: {
    position: 'absolute',
    left: 0,
    fontSize: 16
  },
  title: {
    margin: 'auto'
  },
  langBtn: {
    position: 'absolute',
    right: 8,
    top: 12
  }
};

function Header({
  classes,
  location,
  headerTitle,
  useBack,
  onBack,
  backLabel,
  onSideMenuSelection
}) {
  const permissions = useSelector(state => state.login.permissions);
  const loggedIn = useSelector(state => state.login.loggedIn);
  const [openMenu, setOpenMenu] = useState(false);
  const appBarClasses = useMemo(() => ({ root: classes.header }), [classes]);
  const toolbarClasses = useMemo(() => ({ root: classes.root }), [classes]);

  const toggleMenu = useCallback(() => {
    setOpenMenu(!openMenu);
  }, [openMenu]);

  const pageTitle = useMemo(() => {
    if (headerTitle) {
      return headerTitle;
    }
    const currentHash = location.pathname.slice(1);
    const currentMenuItem = (permissions.menuItems || []).find(item => item.route === currentHash);
    if (currentMenuItem) {
      return currentMenuItem.headerLabel || currentMenuItem.label;
    } else {
      return '';
    }
  }, [location, permissions, headerTitle]);

  const onIconClick = useCallback(() => {
    useBack ? onBack && onBack() : toggleMenu();
  }, [useBack, onBack, openMenu]);

  return (
    <AppBar position="fixed" classes={appBarClasses}>
      <Toolbar classes={toolbarClasses}>
        {loggedIn && (
          <React.Fragment>
            <IconButton
              className={classes.menuBtn}
              color="inherit"
              aria-label="Menu"
              onClick={onIconClick}>
              {useBack ? (
                <React.Fragment>
                  <KeyboardArrowLeft />
                  {backLabel && <I18n>{backLabel}</I18n>}
                </React.Fragment>
              ) : (
                <MenuIcon />
              )}
            </IconButton>
            <div className={classes.title}>
              <I18n>{pageTitle}</I18n>
            </div>
            <SideMenu open={openMenu} toggle={toggleMenu} onSelection={onSideMenuSelection} />
          </React.Fragment>
        )}
        <LangButton className={classes.langBtn} />
      </Toolbar>
    </AppBar>
  );
}

export default withRouter(withStyles(styles)(Header));
