import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@material-ui/core';

const styles = {
  root: {
    width: 88,
    height: 88,
    backgroundColor: 'rgb(0,162,226)',
    borderRadius: '44px',
    padding: '19px',
    display: 'inline-block'
  },
  icon: {
    color: 'white',
    fontSize: 50,
    fontWeight: 'lighter',
    textShadow: '0px 1px 2px black'
  }
};

function CreditCardIcon({ classes, className, style }) {
  return (
    <div className={classNames(classes.root, className)} style={style}>
      <Icon className={classes.icon}>credit_card</Icon>
    </div>
  );
}

export default withStyles(styles)(CreditCardIcon);
