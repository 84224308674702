import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles, Drawer, List } from '@material-ui/core';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import { setLoggedOut } from '@redux/login';
import { dispatch } from '@redux/store';
import SideMenuItem from './SideMenuItem';

const styles = {
  wrapper: {
    width: 250
  },
  logo: {
    padding: 15,
    color: 'white',
    height: 180,
    background: 'linear-gradient(90deg, rgb(3,121,190), rgb(0,162,226))'
  },
  logoInner: {
    marginTop: 10
  },
  logout: {
    padding: 15,
    cursor: 'pointer'
  }
};

function SideMenu({ classes, open, toggle, onSelection, history }) {
  const permissions = useSelector(state => state.login.permissions);

  const route = useCallback(
    item => {
      onSelection && onSelection();
      history.push(item.route);
    },
    [onSelection]
  );

  const logout = useCallback(() => {
    onSelection && onSelection();
    dispatch(setLoggedOut());
  }, [onSelection]);

  return (
    <Drawer open={open} onClose={toggle}>
      <div tabIndex={0} role="button" onClick={toggle} onKeyDown={toggle}>
        <div className={classes.wrapper}>
          <List className={classes.logo}>
            <div className={classes.logoInner}>
              <Icon width="150" height="130" type="menu_logo_image" />
            </div>
          </List>
          {(permissions.menuItems || []).map((item, idx) => (
            <SideMenuItem key={idx} item={item} onItemClick={route} />
          ))}
          <List className={classes.logout} onClick={logout}>
            <I18n>G.logout</I18n>
          </List>
        </div>
      </div>
    </Drawer>
  );
}

export default withRouter(withStyles(styles)(SideMenu));
