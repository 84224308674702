export const validations = {
  required: value => {
    return !!!value;
  },
  regex: (value, regex) => {
    var rgx = RegExp(regex);
    return !rgx.test(value);
  },
  minLength: (value, minLength) => {
    return value.length < minLength;
  },
  maxLength: (value, maxLength) => {
    return value.length > maxLength;
  },
  confirmPassword: (password, retypePassword) => {
    return !(password === retypePassword);
  },
  email: value => {
    var rgx = RegExp(/^[A-Za-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
    return !rgx.test(value);
  },
  emailDomain: (email, domains) => {
    if (!domains || !domains.length) {
      return true;
    }
    const emailDomain = email.trim().split('@')[1] || '';
    const lowercase = emailDomain.toLowerCase();
    return domains.every(d => d.toLowerCase() !== lowercase);
  }
};
