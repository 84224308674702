import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import * as cfg from '@root/app-config';
import App from './App';
import AppMinVersionVerifier from './AppMinVersionVerifier';

const wrapperS = { height: '100%' };

export default class Root extends Component {
  render() {
    return (
      <Provider store={this.props.store}>
        <StripeProvider apiKey={cfg.stripe.publishableKey}>
          <HashRouter>
            <div style={wrapperS}>
              <AppMinVersionVerifier>
                <App />
              </AppMinVersionVerifier>
            </div>
          </HashRouter>
        </StripeProvider>
      </Provider>
    );
  }
}
