import React, { useState, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import dialogsManager from './DialogsManager';

const PaperProps = { className: 'scrollable-main' };

/**
 * A wrapper for the dialog. Each dialog will receive the following props:
 * 1) args: the input arguments for the dialog.
 * 2) dismiss: a function to dismiss the dialog with a value.
 */
export default function DialogWrapper({ id, args, props, Component }) {
  const [state, setState] = useState({ isOpen: true, closeValue: undefined });

  const onClose = useCallback(() => {
    setState({ isOpen: false, closeValue: undefined });
  }, []);

  const onExited = useCallback(() => {
    dialogsManager.close(id, state.closeValue);
  }, [state, id]);

  const dismiss = useCallback(closeValue => {
    setState({ isOpen: false, closeValue });
  }, []);

  return (
    <Dialog
      {...props}
      open={state.isOpen}
      PaperProps={PaperProps}
      onClose={onClose}
      onExited={onExited}>
      <Component args={args} dismiss={dismiss} />
    </Dialog>
  );
}
