import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CreditCardIcon from '@components/CreditCardIcon';
import I18n from '@components/I18n';
import { text1 } from '@config/colors';

const styles = {
  root: {
    textAlign: 'center',
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: 40
  },
  text: {
    fontSize: 22,
    color: text1(1)
  },
  icon: {
    marginBottom: 54
  }
};

function AuthChAlreadyConfirmed({ classes }) {
  return (
    <div className={classes.root}>
      <CreditCardIcon className={classes.icon} />
      <I18n component="div" className={classes.text}>
        authenticateCharge.alreadyConfirmed
      </I18n>
    </div>
  );
}

export default withStyles(styles)(AuthChAlreadyConfirmed);
