import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import AlertBtnLabel from './AlertBtnLabel';

const styles = {
  wrapper: {
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    background: 'linear-gradient(#00a2e233, #00a2e200)',
    flex: 1,
    padding: '0 2.5%',
    paddingTop: 55
  },
  text: {
    color: 'unset',
    textAlign: 'center',
    fontSize: '18px',
    marginTop: 35,
    wordWrap: 'break-word'
  },
  dialogActions: {
    margin: 'auto',
    width: '100%',
    display: 'flex'
  },
  twoButton: {
    width: '45%',
    margin: '30px auto'
  },
  oneButton: {
    width: '95%',
    margin: '30px auto'
  }
};

function Alert({ classes, args, dismiss }) {
  const { withoutIcon, success, text, addNo, overrideButtonLabels } = args;
  const closeOK = useCallback(() => dismiss(true), [dismiss]);
  const closeNo = useCallback(() => dismiss(false), [dismiss]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        {!withoutIcon && <Icon type={success ? 'success_icon' : 'error_icon'} />}
        <I18n component="div" className={classes.text}>
          {text}
        </I18n>
      </div>
      <div className={classes.dialogActions}>
        <Button
          className={addNo ? classes.twoButton : classes.oneButton}
          submitOnEnter
          label={<AlertBtnLabel type={addNo ? 'yes' : 'ok'} overrides={overrideButtonLabels} />}
          onClick={closeOK}
        />
        {addNo && (
          <Button
            className={classes.twoButton}
            label={<AlertBtnLabel type="no" overrides={overrideButtonLabels} />}
            onClick={closeNo}
          />
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(Alert);
