import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import I18n from '../components/I18n';

const styles = theme => ({
  formControl: {
    // margin: '8px 0 20px 4px',
  },
  formLabel: {
    // fontSize: 25,
    height: 24,
    marginTop: 10,
    '&:not(:first-child)': {
      // marginLeft: 0,
    }
  },
  group: {
    margin: `1px 0`,
    display: 'unset'
  },
  radio: {
    // padding
  },
  radioIcon: {
    // fontSize: 25,
  }
});

class RadioField extends Component {
  render() {
    const { classes, value, onChange, disabled } = this.props;

    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <RadioGroup value={value} className={classes.group}>
          {this.props.options.map((option, idx) => {
            return (
              <FormControlLabel
                key={idx}
                className={classes.formLabel}
                value={option.id}
                label={option.value}
                onChange={onChange}
                control={
                  <Radio
                    className={classes.radio}
                    color="primary"
                    icon={<RadioButtonUncheckedIcon className={classes.radioIcon} />}
                    checkedIcon={<RadioButtonCheckedIcon className={classes.radioIcon} />}
                    disabled={disabled}
                  />
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default withStyles(styles)(RadioField);
