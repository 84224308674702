export const saveInStorage = (storageName, data) => {
  localStorage.setItem(storageName, JSON.stringify(data));
};

export const removeFromStorage = (storageName, data) => {
  localStorage.removeItem(storageName);
};

export const getFromStorage = storageName => {
  return localStorage.getItem(storageName) ? JSON.parse(localStorage.getItem(storageName)) : null;
};
