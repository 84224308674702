import React, { useCallback } from 'react';
import { List, Divider } from '@material-ui/core';
import I18n from '@components/I18n';

const listS = { padding: 15, cursor: 'pointer' };

function SideMenuItem({ item, onItemClick }) {
  const onClick = useCallback(() => onItemClick(item), [item]);

  return (
    <React.Fragment>
      <List style={listS} onClick={onClick}>
        <I18n>{item.label}</I18n>
      </List>
      <Divider />
    </React.Fragment>
  );
}

export default React.memo(SideMenuItem);
