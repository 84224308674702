import React from 'react';
import I18n from '../components/I18n';

export default class FieldError extends React.Component {
  render() {
    const errorS = {
      fontSize: 13,
      marginBottom: 20,
      color: '#f44336'
    };

    return (
      <div style={errorS}>
        <I18n {...this.props.errors.errObj}>{this.props.errors.errKey}</I18n>
      </div>
    );
  }
}
