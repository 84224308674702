import { createAction, handleActions } from 'redux-actions';

export const startFetching = createAction('[myParkingSlots] START_FETCHING');
export const doneFetching = createAction('[myParkingSlots] DONE_FETCHING');
export const updateData = createAction('[myParkingSlots] UPDATE_DATA');

const initialState = {
  lastUpdate: null, // timestamp.
  isFetching: false, // Are we currently fetching parking-slots.
  data: [] // Array of parking-slots.
};

export default handleActions(
  {
    [startFetching]: state => ({ ...state, isFetching: true }),

    [doneFetching]: state => ({ ...state, isFetching: false }),

    [updateData]: (state, { payload }) => ({
      ...state,
      lastUpdate: Date.now(),
      data: payload
    })
  },
  initialState
);
