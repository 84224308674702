import { createAction, handleActions } from 'redux-actions';

export const incrementBusyIndicator = createAction('[busyIndicator] INCREMENT');
export const decrementBusyIndicator = createAction('[busyIndicator] DECREMENT');
export const setBusyIndicatorMode = createAction('[busyIndicator] SET_MODE');

const initialState = {
  counter: 0,
  mode: 'app'
};

export default handleActions(
  {
    [incrementBusyIndicator]: state => ({ ...state, counter: state.counter + 1 }),
    [decrementBusyIndicator]: state => ({ ...state, counter: Math.max(0, state.counter - 1) }),
    [setBusyIndicatorMode]: (state, { payload }) => ({ ...state, mode: payload })
  },
  initialState
);
