import { setMyBookings, setParkingSlotsAvailability } from '@redux/bookings';
import dialogsManager from '@dialogs/DialogsManager';
import { dispatch, getState } from '@redux/store';
import { api } from '@utils/data';
import moment from 'moment';

export function getMyBookings() {
  const parkingLotId = getState().parkingLot._id;
  return api({
    type: 'getMyBookings',
    urlParams: { parkingLotId }
  }).then(res => {
    dispatch(setMyBookings(res));
  });
}

export async function bookParkingSlot(parkingSlotId, range) {
  await api({
    type: 'bookParkingSlot',
    data: { parkingSlotId, start: range[0], end: range[1] }
  });
  const bookAnother = await dialogsManager.openAlert({
    text: 'searchParkingSlot.successBookingMsg',
    addNo: true,
    success: true
  });
  await getMyBookings();
  return bookAnother;
}

export function cancelBooking(bookingId) {
  return api({
    type: 'cancelBooking',
    data: { bookingId }
  });
}

export function getParkingSlotsAvailabilityByDate(date) {
  const parkingLotId = getState().parkingLot._id;

  const range = [];
  const start = moment(date[0], 'YYYY.MM.DD');
  const end = moment(date[1], 'YYYY.MM.DD');
  for (let d = start; d.isSameOrBefore(end); d.add(1, 'days')) {
    range.push(d.format('YYYY.MM.DD'));
  }

  return api({
    type: 'getParkingSlotsAvailabilityByDate',
    data: { parkingLotId, range }
  }).then(res => {
    dispatch(setParkingSlotsAvailability(res, date));
  });
}
