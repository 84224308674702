import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import FormActionButtons from '@components/FormActionButtons';
import dialogsManager from '@dialogs/DialogsManager';
import { stripe } from '@utils/stripe';
import { api } from '@utils/data';
import AuthChAlreadyConfirmed from './AuthChAlreadyConfirmed';
import AuthChConfirm from './AuthChConfirm';
import { BACK_TO_APP_PAGE } from './authChUtils';

const styles = {
  root: {
    height: '100%'
  },
  main: {
    height: '100%',
    paddingBottom: 84,
    position: 'relative'
  }
};

function AuthChHasData({ classes, data, history }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [didSubmit, setDidSubmit] = useState(false);
  const [showAlreadyConfirmed, setShowAlreadyConfirmed] = useState(false);

  const onSubmit = useCallback(
    async event => {
      event.preventDefault();
      if (didSubmit) {
        return setShowAlreadyConfirmed(true);
      }
      setIsSubmitting(true);
      try {
        const { clientSecret } = await api({
          type: 'prepareConfirmPayment',
          data: { transactionId: data.transactionId }
        });
        const result = await stripe.handleCardPayment(clientSecret, {
          payment_method: data.paymentMethodId
        });
        setIsSubmitting(false);
        if (result.error) {
          // TODO(ahuvi): Discuss with product manager. We could also offer the user the option to change cards.
          dialogsManager.openAlert({
            text: 'authenticateCharge.authFailed',
            success: false
          });
        } else {
          await dialogsManager.openAlert({ text: 'authenticateCharge.authSuccess' });
          setDidSubmit(true);
        }
      } catch (err) {
        setIsSubmitting(false);
      }
    },
    [data, history, didSubmit]
  );

  const onCancel = useCallback(() => {
    history.replace(BACK_TO_APP_PAGE);
  }, []);

  return (
    <form className={classes.root} onSubmit={onSubmit}>
      <div className={classes.main}>
        {data.isPaymentConfirmed || showAlreadyConfirmed ? (
          <AuthChAlreadyConfirmed />
        ) : (
          <AuthChConfirm data={data} />
        )}
      </div>
      <FormActionButtons
        hideCancel
        disableSubmit={data.isPaymentConfirmed || showAlreadyConfirmed}
        submitLabel="G.done"
        isSubmitting={isSubmitting}
        onCancel={onCancel}
      />
    </form>
  );
}

export default withRouter(withStyles(styles)(AuthChHasData));
