import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Header from '@components/Header';
import layout from '@config/layout';
import { blue2 } from '@config/colors';
import { api } from '@utils/data';
import { changeLanguage } from '@utils/i18nService';
import { initLogin } from '@utils/auth';
import AuthChLoadingData from './AuthChLoadingData';
import AuthChHasData from './AuthChHasData';
import { BACK_TO_APP_PAGE } from './authChUtils';

const styles = {
  wrapper: {
    height: '100%',
    margin: 'auto',
    position: 'relative'
  },
  underHeader: {
    marginTop: layout.HEADER_HEIGHT,
    height: `calc(100% - ${layout.HEADER_HEIGHT}px)`
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 194,
    background: `linear-gradient(to bottom, ${blue2}, white)`,
    opacity: 0.2
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
};

function AuthenticateChargePage(props) {
  const { history, classes, match } = props;
  const [serverRes, setServerRes] = useState(null);

  useEffect(() => {
    if (match.params.lang && match.params.lang !== 'null') {
      changeLanguage(match.params.lang);
    }

    api({
      type: 'getAuthenticateChargeDetails',
      urlParams: { token: match.params.token },
      disableErrorHandle: true
    }).then(res => {
      initLogin(res.tokens).then(() => {
        setServerRes(res);
      });
    });
  }, []);

  const onBack = useCallback(() => {
    history.replace(BACK_TO_APP_PAGE);
  }, [history]);

  return (
    <div className={classes.wrapper}>
      <Header headerTitle="" useBack onBack={onBack} backLabel="authenticateCharge.backLabel" />
      <div className={classes.underHeader}>
        <div className={classes.background}></div>
        <div className={classes.content}>
          {serverRes ? <AuthChHasData data={serverRes} /> : <AuthChLoadingData />}
        </div>
      </div>
    </div>
  );
}

export default withRouter(withStyles(styles)(AuthenticateChargePage));
