import { createAction, handleActions } from 'redux-actions';

export const setAccount = createAction('SET_ACCOUNT');

const initialState = {};

export default handleActions(
  {
    [setAccount]: (state, action) => {
      return action.payload;
    }
  },
  initialState
);
