import axios from 'axios';
import { template } from 'lodash';
import { incrementBusyIndicator, decrementBusyIndicator } from '@redux/busyIndicator';
import { setLoggedOut } from '@redux/login';
import { appMinVersionFailed } from '@redux/app';
import dialogsManager from '@dialogs/DialogsManager';
import store, { dispatch } from '@redux/store';
import * as cfg from '@root/app-config';
import { getStoredCredentials, saveCredentials, clearCredentials } from './auth';
import { tr } from './i18nService';
import { apiMap } from './apiMap';

const urlPrefix = 'cordova' in window ? cfg.baseUrl : '';
const defaultHeaders = {};

export function download(cfg) {
  refreshToken().then(() => {
    let credentials = getStoredCredentials();
    let url = urlPrefix + apiMap[cfg.type].url;
    cfg.urlParams.accessToken = credentials.accessToken;
    if (cfg.urlParams) {
      url = template(url)(cfg);
    }
    var a = document.createElement('a');
    a.href = url;
    a.click();
  });
}

export function api(cfg) {
  !cfg.disableBI && dispatch(incrementBusyIndicator());
  return new Promise((resolve, reject) => {
    const apiItem = apiMap[cfg.type];
    if (apiItem.mock) {
      return setTimeout(() => {
        dispatch(decrementBusyIndicator());
        resolve(apiItem.mock);
      }, 300);
    }
    if (apiItem.errMock) {
      return errorHandler(apiItem.errMock, cfg, resolve, reject);
    }
    let url = urlPrefix + apiItem.url;
    if (cfg.urlParams) {
      url = template(url)(cfg);
    }
    axios({
      url,
      data: cfg.data,
      method: apiItem.method.toLowerCase(),
      headers: {
        ...defaultHeaders,
        ...(cfg.dontSendHeaders ? {} : getHeaders())
      }
    }).then(
      res => {
        !cfg.disableBI && dispatch(decrementBusyIndicator());
        resolve(res.data);
      },
      err => {
        errorHandler(err, cfg, resolve, reject);
      }
    );
  });
}

export function setHeader(key, value) {
  defaultHeaders[key] = value;
}

function errorHandler(err, cfg, resolve, reject) {
  !cfg.disableBI && dispatch(decrementBusyIndicator());
  if (err.response.status === 417) {
    // Minimum App version check failed.
    const { linkToStore, platform } = err.response.data;
    dispatch(appMinVersionFailed({ linkToStore, platform }));
    return reject(err);
  }
  if (err.response.status === 403) {
    api({
      type: 'refreshToken',
      data: {
        refreshToken: getStoredCredentials().refreshToken
      },
      disableBI: true,
      dontSendHeaders: true
    }).then(
      res => {
        saveCredentials(res);
        api(cfg).then(resolve, reject);
      },
      err => {
        console.log('refresh token err', err);
        clearCredentials();
        reject(err);
      }
    );
  } else {
    if (err.response.status === 401) {
      dispatch(setLoggedOut());
      clearCredentials();
      reject(err.response);
    }

    if (cfg.disableErrorHandle) {
      reject(err.response);
      return false;
    }

    let customErrText = '';
    if (
      err.response.status == 400 &&
      err.response.data &&
      err.response.data.errors &&
      err.response.data.errors.length
    ) {
      err.response.data.errors.forEach(
        err => (customErrText += tr(err.errKey, err.errObj) + '<br/>')
      );
    }

    const lang = store.getState().lang.selectedLang;
    const text = customErrText || tr(err.response.data.errKey, err.response.data.errObj, lang);

    dialogsManager.openAlert({ text, success: false }).then(() => {
      if (err.response.status !== 401) {
        reject(err.response);
      }
    });
  }
}

function getHeaders() {
  let credentials = getStoredCredentials();
  if (credentials && credentials.accessToken) {
    return {
      Authorization: credentials.accessToken
    };
  }
  return {};
}

function refreshToken() {
  return api({
    type: 'refreshToken',
    data: {
      refreshToken: getStoredCredentials().refreshToken
    },
    disableBI: true
  }).then(res => {
    saveCredentials(res);
    return res;
  });
}
