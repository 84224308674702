import { createAction, handleActions } from 'redux-actions';

export const setMyBookings = createAction('SET_MY_BOOKINGS');
export const setParkingSlotsAvailability = createAction('SET_PARKING_SLOT_AVAILABILITY');
export const resetSearch = createAction('RESET_SEARCH');

const initialState = {
  myBookings: [],
  availableParkingSlots: []
};

export default handleActions(
  {
    [setMyBookings]: (state, action) => {
      return {
        ...state,
        myBookings: action.payload.bookings
      };
    },
    [setParkingSlotsAvailability]: (state, action) => {
      return {
        ...state,
        availableParkingSlots: action.payload.availableParkingSlots
      };
    },
    [resetSearch]: (state, action) => {
      return {
        ...state,
        availableParkingSlots: []
      };
    }
  },
  initialState
);
