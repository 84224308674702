import React from 'react';
import { withStyles } from '@material-ui/core';
import Icon from '@components/Icon';

const styles = {
  bottomSection: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 160,
    maxWidth: 750,
    margin: 'auto',
    opacity: 0.5
  }
};

function BottomSection({ classes, style }) {
  return (
    <div className={classes.bottomSection} style={style}>
      <Icon type="footer_background" width="100%" height="100%" />
    </div>
  );
}

export default withStyles(styles)(BottomSection);
