import { createAction, handleActions } from 'redux-actions';

export const setParkingLot = createAction('SET_PARKING_LOT');

const initialState = {};

export default handleActions(
  {
    [setParkingLot]: (state, action) => {
      return action.payload;
    }
  },
  initialState
);
