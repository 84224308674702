export function rnd() {
  return parseInt(Math.random() * 100000);
}

export const uuid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

/**
 * Formats the server price (integer of amount of cents), to
 * the UI price (a string: main + '.' + cents).
 */
export function formatPrice(serverPrice) {
  const string = serverPrice.toString();
  const main = string.slice(0, -2);
  const cents = string.slice(-2);
  return `${main}.${cents}`;
}

export function hideSplashScreen() {
  if ('cordova' in window) {
    document.addEventListener('deviceready', () => {
      navigator.splashscreen.hide();
    });
  }
}

export function getBankAccountUI(bankAccount) {
  return bankAccount ? `${bankAccount.bankName} **** ${bankAccount.last4}` : undefined;
}
